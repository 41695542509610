/* eslint-disable no-return-assign */
// design configuration component
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Subscription } from "rxjs";

import ReactTooltip from "react-tooltip";
import WhelenWImg from "../../assets/images/Whelen-Bowtie-White.svg";
import WhelenRImg from "../../assets/images/W-Red.svg";
import HeaderComponent from "./HomeHeader";
import configService from "../../services/ConfigService";
import commonUtiles from "../../services/CommonUtiles";
import Helpers from "../../helper/helper";
import ZoneServices from "../../services/ZoneService";
import Logout from "../../helper/logout";
import Footer from "./Footer";
import { SPECS_1900, VEHICLESIZE_1900 } from "../../services/Constant";

const reactStringReplace = require("react-string-replace");

const HomePage = ({ history }) => {
  const SIZES = {
    GREATER_THAN: "GREATER_THAN_25",
    LESS_THAN: "LESS_THAN_25",
    ANY_SIZE: "ANY_SIZE",
  };
  const vehicleSizesGL = [
    { value: SIZES.GREATER_THAN, label: "Greater than 25'" },
    { value: SIZES.LESS_THAN, label: "Less than 25'" },
  ];
  const vehicleSizesAny = [{ value: SIZES.ANY_SIZE, label: "Any Size" }];
  const vehicleSizes1900 = [
    { value: SIZES.GREATER_THAN, label: VEHICLESIZE_1900.GREATER_THAN },
    { value: SIZES.LESS_THAN, label: VEHICLESIZE_1900.LESS_THAN },
  ];
  const [standardList, setStandardList] = useState([]);

  const [stdOpt, setStdOpt] = useState("");
  const [stdOptTooltip, setStdOptTooltip] = useState("");
  const [stdMenuOpen, setStdMenuOpen] = useState("false");
  const [aptOpt, setAptOpt] = useState("");
  const [vehicleSizes, setVehicleSizes] = useState([]);

  const [configData, setConfigData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchedVal, setSearchedVal] = useState("");
  const [pageSize, setPageSize] = useState(Helpers.getPageSizes()[1]);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState("");
  const [prePageTokens, setPrePageTokens] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");
  const [landing, setLanding] = useState(true);
  const [isLogout, setIsLogout] = useState(false);
  const [isFullCZone, setIsFullCZone] = useState(true);
  const subscription = new Subscription();
  let stdOptTooltipRef = useRef(null);
  const stdOptMenuRef = useRef(null);

  const sortInside = {
    column: "configName",
    direction: "asc",
  };
  const [sort, setSort] = useState(sortInside);
  const [configList, setConfigList] = useState([]);
  const startDesigning = (e) => {
    e.preventDefault();
    const date = new Date();
    const day = date.toDateString().slice(date.toDateString().indexOf(" ") + 1, date.toDateString().length);
    const currentTime = date.toLocaleTimeString().split(":").join(".");
    const currentDay = day.split(" ").join("-");
    const currentDate = `${currentDay}-${currentTime}`;
    const request = { configName: `${stdOpt.label}-${currentDate}`, vehicleSize: aptOpt.value, stdName: stdOpt.value };
    configService.setConfigToSessionStorage(request);
    history.push({
      pathname: "/zone",
    });
  };

  const viewConfigData = (e, configDetails) => {
    e.preventDefault();
    const confDetail = JSON.parse(JSON.stringify(configDetails));
    confDetail.vehicleSize = confDetail.vehicleSize ? confDetail.vehicleSize : aptOpt.value;
    const request = {};
    request.configDetails = confDetail;
    request.vehicleSize = confDetail.vehicleSize ? confDetail.vehicleSize : aptOpt.value;
    request.stdName = configDetails.standardName;
    configService.setConfigToSessionStorage(request);
    history.push({
      pathname: "/zone",
    });
  };
  const checkForCFullZone = (obj) =>
    obj?.value === "Ontario_5.0" || obj?.value === "KKK_A_1822G" || obj?.value === "CAAS_GSV_V3.0";
  const onStdChange = (e) => {
    setStdOpt(e);
    if (e.label.length > 15) setStdOptTooltip(e.label);
    setIsFullCZone(checkForCFullZone(e));
    if (checkForCFullZone(e)) {
      setVehicleSizes([...vehicleSizesAny]);
      setAptOpt(vehicleSizesAny[0]);
    } else {
      setVehicleSizes([...vehicleSizesGL]);
      setAptOpt(vehicleSizesGL[0]);
    }
    if (e.value === SPECS_1900.CH10 || e.value === SPECS_1900.CH31) {
      setVehicleSizes([...vehicleSizes1900]);
      setAptOpt(vehicleSizes1900[0]);
    }
  };

  const onAptChange = (e) => {
    setAptOpt(e);
  };

  // Function use for sorting table data in ascending and descending order.
  const onSort = (column, data, listing) => {
    const direction = !listing ? (sort.direction === "asc" ? "desc" : "asc") : "asc";
    const allEqual = data.every((item) => data[0][column] === item[column]);
    let sortedData;
    if (allEqual) {
      sortedData = data.reverse();
    } else {
      sortedData = data.sort((a, b) => commonUtiles.compareValues(a, b, column, direction));
      if (direction === "desc") {
        sortedData.reverse();
      }
    }
    setConfigList(sortedData);
    setSort({
      column,
      direction,
    });
    return sortedData;
  };
  const getStdTypes = () => {
    configService
      .getSystemTypes()
      .then((response) => {
        const data = response.data.sort((a, b) => commonUtiles.compareValues(a, b, "name", "asc"));
        const stdList = [];
        const footerList = [];
        data.forEach((element) => {
          const obj = {};
          obj.value = element.id;
          obj.label = element.name;
          stdList.push(obj);
          const footerObj = {};
          footerObj.value = element.id;
          footerObj.label = element.footerText;
          footerList.push(footerObj);
        });
        setStandardList(stdList);
        localStorage.setItem("standardList", JSON.stringify(stdList));
        localStorage.setItem("footerList", JSON.stringify(footerList));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Method use to get configData' data
  const getConfigurations = (pageToken, filterVal, pageVal) => {
    setConfigList([]);
    setLoading(true);
    configService
      .getConfigurations(pageVal.value, pageToken, filterVal)
      .then((response) => {
        if (ZoneServices.getLightsDataFromLocalStorage()) {
          setLoading(false);
        }
        if (landing) {
          setConfigData(JSON.parse(JSON.stringify(response.data.configData)));
        }
        onSort("configName", response.data.configData, true);
        if (response.data.lastEvaluatedKey && response.data.configData.length) {
          setNextPageToken(response.data.lastEvaluatedKey);
          setIsNextBtnActive("");
        } else {
          setIsNextBtnActive("disabled");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const onReset = () => {
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    setSearchVal("");
    setSearchedVal("");
    setShowReset(false);
  };

  // function for get lightsData
  const getData = async () => {
    const [lightData, lightBars] = await Promise.all([ZoneServices.getLightsData(), ZoneServices.getLightBarsData()]);
    if ((lightData && lightData.data) || (lightBars && lightBars.data)) {
      const mergedData = [...lightData.data, ...lightBars.data];
      ZoneServices.setLightsDataToLocalStorage(mergedData);
      setLoading(false);
    }
  };

  // Function is used to get config data on page load.
  useEffect(async () => {
    sessionStorage.clear();
    const stdList = await configService.getStandardListFromLocalStorage();
    if (!stdList) {
      await getStdTypes();
    } else {
      setStandardList(stdList);
    }
    const initialConfigPageSize = { value: 3 };
    getConfigurations("", "", initialConfigPageSize); // when creating new config revisit 3 recent configs
    const lights = await ZoneServices.getLightsDataFromLocalStorage();
    if (!lights) {
      await getData();
    }
    subscription.add(
      configService.getLogoutOperations().subscribe((data) => {
        if (data) {
          setIsLogout(true);
        }
      })
    );
  }, []);

  // Function uses for clear filter data
  const onLoad = () => {
    onReset();
    getConfigurations("", "", pageSize);
  };

  // Function is used to get dada for View All Configurations pop up
  const onViewAllConfig = () => {
    setLanding(false);
    if (configData?.length) getConfigurations("", "", pageSize);
  };

  // Function uses to filter configData' data
  const handleSearch = () => {
    if (searchVal) {
      setSort(sortInside);
      setNextPageToken("");
      setSearchedVal(searchVal);
      setIsPrevBtnActive("disabled");
      setPrePageTokens([]);
      getConfigurations("", searchVal, pageSize);
      setShowReset(true);
    }
  };
  // function invoke on Next button click
  const btnNextClick = (e) => {
    e.preventDefault();
    prePageTokens.push(nextPageToken);
    setIsPrevBtnActive("");
    getConfigurations(nextPageToken, searchVal, pageSize);
  };

  // function invoke when Previous button click
  const btnPrevClick = (e) => {
    e.preventDefault();
    if (prePageTokens.length > 1) {
      getConfigurations(prePageTokens[prePageTokens.length - 2], searchVal, pageSize);
      prePageTokens.pop();
    } else {
      prePageTokens.pop();
      getConfigurations("", searchVal, pageSize);
      setIsPrevBtnActive("disabled");
    }
    setIsNextBtnActive("");
  };
  // Enter key handler
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };
  // function use to set filter value
  const updateInputValue = (evt) => {
    setSearchVal(evt.target.value);
    if (evt.target.value) {
      setShowReset(true);
    } else {
      setShowReset(false);
      if (searchVal) {
        onLoad();
      }
    }
  };

  // placeholder to solve eslint error
  const handleClick = () => {};

  // Function uses for clear filter data
  const onResetHandler = (e) => {
    e.preventDefault();
    onLoad();
  };

  // handle onChange event of the dropdown
  const pageChange = (e) => {
    setPageSize(e);
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    getConfigurations("", searchVal, e);
  };

  const renderBody = () =>
    configList?.length ? (
      configList.map((config) => (
        <tr key={config.id}>
          <td className="conf-ht">
            <a
              href="#"
              data-dismiss="modal"
              onClick={(e) => {
                viewConfigData(e, config);
              }}
              className="text-white"
            >
              <div className="max-conf-name text-eclips" data-tip={config.configName} data-for={config.configName}>
                {reactStringReplace(config.configName, searchedVal, (match, i) => (
                  <span key={i} style={{ color: "red" }}>
                    {match}
                  </span>
                ))}
              </div>
            </a>
          </td>
          <ReactTooltip
            id={config.configName}
            place="right"
            multiline
            type="light"
            effect="solid"
            className="zone-tooltip"
          />
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <div className="no-records-alert text-center">No Configuration Available</div>
        </td>
      </tr>
    );

  const handleStdOptTooltip = () => {
    if (stdMenuOpen === "false") {
      setStdOptTooltip("");
      ReactTooltip.hide(stdOptTooltipRef);
      setStdMenuOpen("true");
    } else {
      setStdMenuOpen("false");
    }
  };

  const topThree = () =>
    configData?.length ? (
      configData.map((config, index) =>
        index < 3 ? (
          <div>
            <div className="mg-tp conf-ht" key={config.id}>
              <a
                href="#"
                onClick={(e) => {
                  viewConfigData(e, config);
                }}
                className="link mt-0 pl-0"
              >
                <div className="max-conf-name text-eclips" data-tip={config.configName} data-for={config.configName}>
                  {config.configName}
                </div>
              </a>
              <br />
            </div>
            <ReactTooltip
              id={config.configName}
              place="right"
              multiline
              type="light"
              effect="solid"
              className="zone-tooltip"
            />
          </div>
        ) : null
      )
    ) : (
      <div className="mg-tp cr-rd">No Configuration Available</div>
    );

  // User clicks on Log out button
  const onSignOut = () => <Logout />;
  return (
    <>
      {isLogout ? onSignOut() : null}
      <div className="homepage-header">
        <HeaderComponent history={history} />
      </div>
      <section className="theme-bg home-page flex-column">
        <div className="container" style={{ marginBottom: "1rem" }}>
          <div className="row justify-content-center">
            <div className="home-block">
              <div className="brand-logo">
                <img alt="" src={WhelenRImg} className="img-fluid" />
              </div>
              <h3 className="home-subtitle text-white text-uppercase">Apparatus Outfitting Guide</h3>
              <h1 className="home-title">Start a new configuration</h1>
              <form className="home-form">
                <div className="form-row justify-content-between">
                  <div className="form-group">
                    <label htmlFor="standard">Standard</label>
                    <div
                      data-tip={stdOptTooltip}
                      role="none"
                      data-for="standard-dropdown"
                      onClick={handleStdOptTooltip}
                      ref={(ref) => (stdOptTooltipRef = ref)}
                    >
                      <Select
                        value={stdOpt}
                        onChange={onStdChange}
                        options={standardList}
                        className="select-dd"
                        placeholder="Please Select"
                        autoFocus={false}
                        isSearchable={false}
                        styles={Helpers.getDropDownStyle(false)}
                        ref={stdOptMenuRef}
                        menuIsOpen={stdMenuOpen === "true"}
                      />
                      <ReactTooltip
                        id="standard-dropdown"
                        place="top"
                        multiline
                        type="light"
                        effect="solid"
                        className="zone-tooltip"
                      />
                    </div>
                  </div>
                  <div className={`form-group ${isFullCZone ? "cursor-na" : "cursor-pt"}`}>
                    <label htmlFor="Vehicle-Size">Vehicle Size</label>
                    <Select
                      value={aptOpt}
                      onChange={onAptChange}
                      options={vehicleSizes}
                      className="select-dd"
                      placeholder="Please Select"
                      isDisabled={isFullCZone}
                      autoFocus={false}
                      isSearchable={false}
                      styles={Helpers.getDropDownStyle(isFullCZone)}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-red"
                    onClick={startDesigning}
                    disabled={loading || !(stdOpt && aptOpt)}
                  >
                    Start designing
                  </button>
                </div>
              </form>
              <div className="horizontal-line" />
              <div className="m-auto revisit-link">
                <div className="revisit-linklist revisit-ht d-flex flex-column text-left">
                  <p className="text-white text-center">Revisit an existing configuration</p>
                  {loading && landing ? (
                    <div className="mt-7">
                      <div className="loader m-auto" />
                    </div>
                  ) : (
                    <div>{topThree()}</div>
                  )}
                </div>
              </div>
              <div className="text-center mt-10">
                <button
                  type="submit"
                  className="btn btn-black text-capitalize"
                  data-toggle="modal"
                  data-target="#configurationList"
                  data-backdrop="static"
                  onClick={onViewAllConfig}
                >
                  View All
                  <br />
                  Configurations
                </button>
                <div className="modal fade" id="configurationList" tabIndex="-1">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="d-flex changepass-title mb-0">
                        <h2 className="text-white text-uppercase text-left">Configuration List</h2>
                        <div className="close-icon text-right mt-5">
                          <button type="button" className="close" data-dismiss="modal" onClick={onReset}>
                            <span aria-hidden="true">
                              <i className="fa fa-times text-red" />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body" style={{ height: "690px", padding: 0, marginTop: "-10px" }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="form-group has-search">
                            <span className="fa fa-search form-control-feedback" />
                            <input
                              type="text"
                              className="form-control"
                              onKeyDown={onKeyDownHandler}
                              value={searchVal}
                              onChange={updateInputValue}
                              placeholder="Search for Name"
                            />
                            {showReset ? (
                              <i
                                aria-hidden="true"
                                className="fa fa-times close-icon"
                                onClick={onResetHandler}
                                onKeyDown={handleClick}
                                role="button"
                                tabIndex={0}
                              />
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-4 table-max">
                          <table className="table table-dark table-bordered">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  onClick={() => onSort("configName", configList)}
                                  className="header-sticky text-uppercase"
                                >
                                  Name
                                  <span className={commonUtiles.setArrow(sort, "configName")} />
                                </th>
                              </tr>
                            </thead>
                            {loading ? (
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="mt-5 mb-5">
                                      <div className="loader m-auto" />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            ) : (
                              <tbody>{renderBody()}</tbody>
                            )}
                          </table>
                        </div>
                        {(!isPrevBtnActive || !isNextBtnActive) && !loading ? (
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb flex-end mb-0 py-0 px-0 mt-4">
                              <li>
                                <Select
                                  onChange={pageChange}
                                  options={Helpers.getPageSizes()}
                                  name="role"
                                  className="select-xsdd mr-3"
                                  autoFocus={false}
                                  isSearchable={false}
                                  value={pageSize}
                                  styles={Helpers.getDropDownStyleForPage()}
                                />
                              </li>
                              {isPrevBtnActive === "disabled" ? (
                                <li className="breadcrumb-item disable" aria-current="page">
                                  <div className="disable">Prev</div>
                                </li>
                              ) : (
                                <li className="page-item">
                                  <li className="breadcrumb-item" aria-current="page">
                                    <a href="#" onClick={btnPrevClick}>
                                      Prev
                                    </a>
                                  </li>
                                </li>
                              )}
                              <div className="pipe">|</div>
                              {isNextBtnActive === "disabled" ? (
                                <li className="breadcrumb-item">
                                  <div className="disable">Next</div>
                                </li>
                              ) : (
                                <li className="breadcrumb-item">
                                  <a href="#" onClick={btnNextClick}>
                                    Next
                                  </a>
                                </li>
                              )}
                            </ol>
                          </nav>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-logo mx-auto">
                <a href="https://www.whelen.com/" rel="noreferrer" target="_blank">
                  <img alt="" src={WhelenWImg} className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};
export default HomePage;
