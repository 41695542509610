// component for display zone card section
import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Helpers from "../../../helper/helper";
import LightBarPreviewComponent from "./LightBarPreviewComponent";
import ZoneServices from "../../../services/ZoneService";
import { LightbarPreviewConstant as LPC } from "../../../services/Constant";
import config from "../../../config/config";

const { ERROR_MESSAGE } = config;
const ZoneCards = ({
  renderData,
  onChangeQuantity,
  onChangeCallIntensity,
  onChangeBlockIntensity,
  setObstruct,
  deleteLightsFromZone,
  zone,
  setRotation,
  isOntario,
  isMirrorToZoneB,
  isMirrorToZoneD,
}) => {
  const [deleteIndex, setDeleteIndex] = useState("");
  const [isLightbarPreviewModelOpen, setIsLightbarPreviewModelOpen] = useState(false);
  const [lightbarData, setLightbarData] = useState([]);
  const [perspectiveWH, setperspectiveWH] = useState({ PW: 1, PH: 1 });
  const [tooltipData, setTooltipData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    setDeleteIndex("");
  }, [renderData]);

  const getLightBarPreviewData = (zoneData) => {
    if (!loading) {
      setTooltipData(zoneData.tooltipData);
      setLoading(true);
      ZoneServices.getLightbarPreviewData(zoneData.configId, zoneData.lightId)
        .then((response) => {
          if (response?.data && response?.data?.length) {
            let maxH = 0;
            let maxW = 0;
            let Wd = 0;
            let Hd = 0;
            response.data.forEach((d) => {
              if (Number(d.top) > maxH) {
                maxH = Number(d.top);
                Hd = Number(d.height);
              }
              if (Number(d.left) > maxW) {
                maxW = Number(d.left);
                Wd = Number(d.width);
              }
            });
            const ratio = (maxW + Wd) / (maxH + Hd);
            const PW = Number(LPC.LightbarWidth / (maxW + Wd)).toFixed(2);
            const PH = Number(LPC.LightbarWidth / ratio).toFixed(0);
            setperspectiveWH({ PW, PH });
            setLightbarData(response.data);
            setIsLightbarPreviewModelOpen(true);
            setLoading(false);
          } else {
            if (response?.data?.statusCode === 500) {
              Helpers.displayNotification(ERROR_MESSAGE.NO_DATA_FOUND_LIGHTBAR, "error");
            } else {
              Helpers.displayNotification(ERROR_MESSAGE.NO_OUTPUT_DATA_FOUND_LIGHTBAR, "error");
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          Helpers.displayErrorMessage(error);
        });
    }
  };

  const onChangeCallingIntensity = (e, index, zones, color) => {
    onChangeCallIntensity(e.value, index, zones, color);
  };
  const onChangeBlockingIntensity = (e, index, zones, color) => {
    onChangeBlockIntensity(e.value, index, zones, color);
  };

  const FindSelectedIndex = (options, value) => options.findIndex((x) => x.value === value);

  const deleteLight = (e, zones, data, zn, index) => {
    setDeleteIndex(index);
    deleteLightsFromZone(e, zones, data, zn);
  };

  const checknChangeQty = (e, index, zones) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(e.target.value)) {
      onChangeQuantity(e.target.value === "0" ? 1 : e.target.value, index, zones);
    }
  };

  const openLightbarPreviewModel = (zoneData) => {
    getLightBarPreviewData(zoneData);
  };

  const closeLightbarPreviewModel = () => {
    setIsLightbarPreviewModelOpen(false);
  };

  return (
    <>
      {loading ? <div className="fullloader fixed-loader" /> : null}
      <Modal
        className="saveasModal"
        backdrop="static"
        tabIndex="-1"
        show={isLightbarPreviewModelOpen}
        onHide={closeLightbarPreviewModel}
      >
        <LightBarPreviewComponent
          lightData={lightbarData}
          PW={perspectiveWH.PW}
          PH={perspectiveWH.PH}
          closeLightbarPreviewModel={closeLightbarPreviewModel}
          tooltipData={tooltipData}
        />
      </Modal>
      {renderData.map((zones, index) => (
        <>
          <div
            key={zones.id}
            className={`${
              ((zones.zone === "B" && isMirrorToZoneB) || (zones.zone === "D" && isMirrorToZoneD)) && "disable-pointer"
            } row sub-details align-item-center close-option`}
          >
            <div className="col-md-3 m-0">
              <p data-for="displayName" data-tip={zones.tooltipData}>
                {zones.showExlm && <i className="fa fa-exclamation pr-1" />}
                {zones.showHash && <i className="fa fa-hashtag pr-1" />}
                {zones.lightType === "Lightbar" ||
                zones.lightType === "ImportedLightbar" ||
                zones.lightType === "Specialty"
                  ? `${zones?.modelNumber ? zones?.modelNumber : ""}`
                  : `${zones?.modelNumber ? zones?.modelNumber : ""}-${zones?.color1 ? zones?.color1 : ""}`}
                {(zones.lightType === "Lightbar" || zones.lightType === "ImportedLightbar") && (
                  <i
                    role="button"
                    aria-label="Preview"
                    style={{ fontSize: "initial" }}
                    className="fa fa-info-circle text-white ml-2"
                    onClick={() => openLightbarPreviewModel(zones)}
                    onKeyDown={() => {}}
                    tabIndex={0}
                  />
                )}
              </p>
              <ReactTooltip id="displayName" place="top" multiline type="light" effect="solid" />
            </div>
            <div className="col-md-9 pr-0 light-width">
              <div className="row justify-content-between custom-width">
                <div className="form-group">
                  <input
                    onChange={(e) => {
                      checknChangeQty(e, index, zone);
                    }}
                    className="form-control br-rad"
                    type="text"
                    value={zones.quantity}
                    maxLength={2}
                  />
                </div>
                <div className="form-group cursor-pt">
                  <Select
                    value={zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.call_intensity)]}
                    onChange={(e) => {
                      onChangeCallingIntensity(e, index, zone);
                    }}
                    options={zones?.intensityOptions ? zones?.intensityOptions : []}
                    className="select-sdd mr-25 mg-top-15"
                    autoFocus={false}
                    isSearchable
                    styles={Helpers.getDropDownStyleZoneRtnAbs()}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                  />
                </div>
                <div className={`form-group ${isOntario ? "cursor-na" : "cursor-pt"}`}>
                  <Select
                    value={zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.block_intensity)]}
                    onChange={(e) => {
                      onChangeBlockingIntensity(e, index, zone);
                    }}
                    options={zones?.intensityOptions ? zones?.intensityOptions : []}
                    className="select-sdd mr-25 mg-top-15"
                    autoFocus={false}
                    isDisabled={isOntario}
                    isSearchable
                    styles={Helpers.getDropDownStyleZoneRtnAbs(isOntario)}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-white btn text-left br-rad"
                    type="button"
                    value={zones?.obstruct?.length ? zones?.obstruct : "None"}
                    onClick={() => setObstruct(zones, index, zone)}
                  />
                </div>
                <div className="form-group mr-0">
                  <input
                    className="form-control bg-white btn text-left br-rad"
                    type="button"
                    value={zones.rotation}
                    onClick={() => setRotation(zones, index, zone)}
                  />
                </div>
                <div className="form-group" style={{ width: "20px" }}>
                  <a
                    href="#"
                    onClick={(e) => {
                      deleteLight(e, zones, renderData, zone, index);
                    }}
                  >
                    {deleteIndex === index ? (
                      <div className="smallloader m-auto" />
                    ) : (
                      <i className="fa fa-times close-option-icon text-danger" />
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {zones.color2 && (
            <div className="row sub-details align-item-center">
              <div className="col-md-3">
                <p data-for="displayName" data-tip={zones.tooltipData}>
                  {zones.showExlm && <i className="fa fa-exclamation pr-1" />}
                  {zones.showHash && <i className="fa fa-hashtag pr-1" />}
                  {`${zones.modelNumber ? zones.modelNumber : ""}-${zones.color2}`}
                </p>
                <ReactTooltip id="displayName" place="top" multiline type="light" effect="solid" />
              </div>
              <div className="col-md-9" style={{ marginLeft: "-2px" }}>
                <div className="row justify-content-between custom-width">
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <input
                      onChange={(e) => {
                        onChangeQuantity(e, index, zone);
                      }}
                      className="form-control br-rad"
                      type="text"
                      value={zones.quantity}
                    />
                  </div>
                  <div className="form-group cursor-pt">
                    <Select
                      value={zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.call_intensity2)]}
                      onChange={(e) => {
                        onChangeCallingIntensity(e, index, zone, 2);
                      }}
                      options={zones?.intensityOptions ? zones?.intensityOptions : []}
                      className="select-sdd mr-25 mg-top-15"
                      autoFocus={false}
                      isSearchable
                      styles={Helpers.getDropDownStyleZoneRtnAbs()}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className={`form-group ${isOntario ? "cursor-na" : "cursor-pt"}`}>
                    <Select
                      value={
                        zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.block_intensity2)]
                      }
                      onChange={(e) => {
                        onChangeBlockingIntensity(e, index, zone, 2);
                      }}
                      options={zones?.intensityOptions ? zones?.intensityOptions : []}
                      className="select-sdd mr-25 mg-top-15"
                      autoFocus={false}
                      isSearchable
                      isDisabled={isOntario}
                      styles={Helpers.getDropDownStyleZoneRtnAbs(isOntario)}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <input
                      className="form-control bg-white btn text-left br-rad"
                      type="button"
                      value={zones?.obstruct?.length ? zones?.obstruct : "None"}
                    />
                  </div>
                  <div className="form-group mr-0" style={{ visibility: "hidden" }}>
                    <input
                      className="form-control bg-white btn text-left br-rad"
                      type="button"
                      value={zones.rotation}
                      onClick={() => setRotation(zones, index, zone)}
                    />
                  </div>
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <a
                      href="#"
                      onClick={(e) => {
                        deleteLight(e, zones, renderData, zone, index);
                      }}
                    >
                      {deleteIndex === index ? (
                        <div className="smallloader m-auto" />
                      ) : (
                        <i className="fa fa-times close-option-icon text-danger" />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {zones.color3 && (
            <div className="row sub-details align-item-center">
              <div className="col-md-3">
                <p data-for="displayName" data-tip={zones.tooltipData}>
                  {zones.showExlm && <i className="fa fa-exclamation pr-1" />}
                  {zones.showHash && <i className="fa fa-hashtag pr-1" />}
                  {`${zones.modelNumber ? zones.modelNumber : ""}-${zones.color3}`}
                </p>
                <ReactTooltip id="displayName" place="top" multiline type="light" effect="solid" />
              </div>
              <div className="col-md-9" style={{ marginLeft: "-2px" }}>
                <div className="row justify-content-between custom-width">
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <input
                      onChange={(e) => {
                        onChangeQuantity(e, index, zone);
                      }}
                      className="form-control br-rad"
                      type="text"
                      value={zones.quantity}
                    />
                  </div>
                  <div className="form-group cursor-pt">
                    <Select
                      value={zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.call_intensity3)]}
                      onChange={(e) => {
                        onChangeCallingIntensity(e, index, zone, 3);
                      }}
                      options={zones?.intensityOptions ? zones?.intensityOptions : []}
                      className="select-sdd mr-25 mg-top-15"
                      autoFocus={false}
                      isSearchable
                      styles={Helpers.getDropDownStyleZoneRtnAbs()}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className={`form-group ${isOntario ? "cursor-na" : "cursor-pt"}`}>
                    <Select
                      value={
                        zones?.intensityOptions[FindSelectedIndex(zones?.intensityOptions, zones.block_intensity3)]
                      }
                      onChange={(e) => {
                        onChangeBlockingIntensity(e, index, zone, 3);
                      }}
                      options={zones?.intensityOptions ? zones?.intensityOptions : []}
                      className="select-sdd mr-25 mg-top-15"
                      autoFocus={false}
                      isSearchable
                      isDisabled={isOntario}
                      styles={Helpers.getDropDownStyleZoneRtnAbs(isOntario)}
                      menuPortalTarget={document.body}
                      menuPosition="fixed"
                    />
                  </div>
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <input
                      className="form-control bg-white btn text-left br-rad"
                      type="button"
                      value={zones?.obstruct?.length ? zones?.obstruct : "None"}
                    />
                  </div>
                  <div className="form-group mr-0" style={{ visibility: "hidden" }}>
                    <input
                      className="form-control bg-white btn text-left br-rad"
                      type="button"
                      value={zones.rotation}
                      onClick={() => setRotation(zones, index, zone)}
                    />
                  </div>
                  <div className="form-group" style={{ visibility: "hidden" }}>
                    <a
                      href="#"
                      onClick={(e) => {
                        deleteLight(e, zones, renderData, zone, index);
                      }}
                    >
                      {deleteIndex === index ? (
                        <div className="smallloader m-auto" />
                      ) : (
                        <i className="fa fa-times close-option-icon text-danger" />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default ZoneCards;
