import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import helper from "../../../helper/helper";
import config from "../../../config/config";
import ConfigService from "../../../services/ConfigService";

const ConfigDetails = ({
  closeConfigDetailPopup,
  systemType,
  systemTypes,
  jobName,
  departmentName,
  vehicleName,
  vinName,
  contractName,
  note,
  loading,
  selectedConfig,
}) => {
  const [sysType, setSysType] = useState("");
  const [job, setJob] = useState("");
  const [dept, setDept] = useState("");
  const [contName, setContName] = useState("");
  const [vclName, setVclName] = useState("");
  const [vnName, setVnName] = useState("");
  const [noteData, setNoteData] = useState("");
  const [imageDetail, setImageDetail] = useState("");
  const [, setFileName] = useState("");
  const [isRequested, setIsRequested] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const fileInput = useRef("");
  const { ERROR_MESSAGE } = config;

  const getCompanyLogo = () => {
    setIsFetching(true);
    ConfigService.getConfigById(selectedConfig)
      .then((response) => {
        if (response && response.data) {
          setImageUrl(response?.data?.companyLogo);
          setFileName(response?.data?.fileName);
          setIsFetching(false);
        }
      })
      .catch(() => {
        setIsFetching(false);
      });
  };
  useEffect(async () => {
    setSysType(systemType);
    setJob(jobName);
    setDept(departmentName);
    setContName(contractName);
    setVclName(vehicleName);
    setVnName(vinName);
    setNoteData(note);
    if (selectedConfig?.id && selectedConfig?.companyLogo) {
      getCompanyLogo();
    }
  }, []);
  const onSaveConfigChanges = () => {
    const data = {};
    data.sysType = sysType;
    data.job = job;
    data.dept = dept;
    data.contName = contName;
    data.vclName = vclName;
    data.vnName = vnName;
    data.noteData = noteData;
    data.imageDetail = imageDetail;
    setIsRequested(true);
    closeConfigDetailPopup(data);
  };
  const onCloseModal = () => {
    closeConfigDetailPopup();
  };
  const onSysChange = (e) => {
    setSysType(e);
  };
  // On Image file select
  const onFileChange = (event) => {
    const reader = new FileReader();
    const fileData = event.target.files[0];
    if (fileData) {
      reader.readAsDataURL(fileData);
      const uploadFileName = fileData.name;
      const checkFile = uploadFileName.toLowerCase();
      const regex = new RegExp("(.*?).(png|svg|jpeg|jpg)$");
      if (!regex.test(checkFile)) {
        helper.displayNotification(ERROR_MESSAGE.INVALID_IMAGE_FILE, "error");
        return;
      }
    }
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        const { height } = this;
        const { width } = this;
        if (height > 500 || width > 500) {
          helper.displayNotification(ERROR_MESSAGE.INVALID_FILE_SIZE, "error");
        } else {
          const imageData = {};
          const strImg = reader.result.split("base64,").pop();
          imageData.companyLogo = strImg;
          imageData.fileName = fileData.name;
          setFileName(fileData.name);
          setImageDetail(imageData);
          setImageUrl(URL.createObjectURL(fileData));
        }
      };
    };
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  return (
    <>
      <div id="ConfigOpt" className="m-auto">
        <div className="modal-dialog  m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={onCloseModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h2 className="modal-title text-white text-uppercase">Configuration Details</h2>
            <form className="config-form">
              <div className="form-row">
                <div className="form-group">
                  <label className="required">System Type</label>
                  <Select
                    value={sysType}
                    onChange={onSysChange}
                    options={systemTypes}
                    placeholder="Please Select"
                    className="select-ldd mr-25 text-capitalize"
                    autoFocus={false}
                    isSearchable={false}
                    styles={helper.getDropDownStyleConfigDetails(false)}
                  />
                </div>
              </div>
              <div className="form-row justify-content-between">
                <div className="form-group">
                  <label>Job</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setJob(e.target.value);
                    }}
                    value={job}
                    autoComplete="off"
                    maxLength={40}
                    placeholder="Enter Job"
                  />
                </div>
                <div className="form-group">
                  <label>Department</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dept"
                    onChange={(e) => {
                      setDept(e.target.value);
                    }}
                    value={dept}
                    autoComplete="off"
                    maxLength={40}
                    placeholder="Enter Department"
                  />
                </div>
              </div>
              <div className="form-row justify-content-between">
                <div className="form-group">
                  <label>Contract</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contract"
                    onChange={(e) => {
                      setContName(e.target.value);
                    }}
                    value={contName}
                    autoComplete="off"
                    maxLength={20}
                    placeholder="Enter Contract"
                  />
                </div>
                <div className="form-group">
                  <label>Vehicle</label>
                  <input
                    type="text"
                    className="form-control"
                    id="Vehicle"
                    onChange={(e) => {
                      setVclName(e.target.value);
                    }}
                    value={vclName}
                    autoComplete="off"
                    maxLength={40}
                    placeholder="Enter Vehicle"
                  />
                </div>
                <div className="form-group">
                  <label>VIN</label>
                  <input
                    type="text"
                    className="form-control"
                    id="VIN"
                    onChange={(e) => {
                      setVnName(e.target.value);
                    }}
                    value={vnName}
                    autoComplete="off"
                    maxLength={40}
                    placeholder="Enter VIN"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group flex-grow-1">
                  <label>Notes</label>
                  <textarea
                    rows="4"
                    cols="50"
                    className="form-control w-100 h-100"
                    id="Note"
                    onChange={(e) => {
                      setNoteData(e.target.value);
                    }}
                    value={noteData}
                    autoComplete="off"
                    placeholder="Enter Note"
                    maxLength={2000}
                  />
                </div>
              </div>
              <label className="w-100 mt-5">Company Logo</label>

              <div className="d-flex justify-content-between mb-2">
                <input
                  type="file"
                  onChange={onFileChange}
                  accept=".png, .svg, .jpeg, .jpg"
                  className="sm-input-file"
                  ref={fileInput}
                />
                <div className="d-flex mb-6">
                  <button type="button" className="btn btn-red" onClick={handleClick}>
                    Upload Image
                  </button>
                  {imageUrl ? (
                    <div>
                      <img src={imageUrl} alt="new" width="60" height="58" className="ml-5 mt-4" />
                      <button
                        type="button"
                        className="img-close-btn close"
                        title="Remove"
                        onClick={() => {
                          setImageUrl("");
                          setImageDetail("");
                          setFileName("");
                          fileInput.current.value = "";
                        }}
                      >
                        <span aria-hidden="true">
                          <i className="fa fa-times text-red" />
                        </span>
                      </button>
                    </div>
                  ) : null}
                  <div className="ml-6 mt-6">{isFetching ? <div className="smallloader m-auto" /> : null}</div>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-10">
                <button type="button" className="btn btn-red w-50 mb-10" onClick={onSaveConfigChanges}>
                  {loading && isRequested ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                  Save Configuration Details
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigDetails;
