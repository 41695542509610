import React from "react";
import { Vector3, Tools } from "@babylonjs/core";
import { Engine, Scene } from "react-babylonjs";
import { LightbarPreviewConstant as LPC, LIGHT_COLORS } from "../../../services/Constant";

const LightBarPreviewComponent = ({ lightData, PW, PH, closeLightbarPreviewModel, tooltipData }) => {
  let lightbar = tooltipData.replace(/<br \/>/gi, "<br>");
  lightbar = lightbar.replace(/<br\/>/gi, "<br>");
  lightbar = lightbar.split("<br>");
  return (
    <>
      <div id="previewLightbar-modal" className="m-auto mg-top-4">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeLightbarPreviewModel}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h2 className="modal-title text-white text-uppercase">PREVIEW</h2>
            <div className="fixed-box">
              <></>
            </div>
            <div className="canvas-position" style={{ height: `${PH}px` }}>
              <Engine canvasId="sample-canvas">
                <Scene clearColor={LPC.SceneColor}>
                  <arcRotateCamera
                    name="camera"
                    target={LPC.CameraPosition}
                    alpha={LPC.CameraAlpha}
                    beta={LPC.CameraBeta}
                    radius={PW > LPC.PerspectiveRatio3 ? LPC.CameraRadius1 : LPC.CameraRadius2}
                    cameraDirection={LPC.CameraDirection}
                  />
                  <hemisphericLight name="light" direction={LPC.LightDirection} />
                  <box
                    position={
                      PW > LPC.PerspectiveRatio2
                        ? LPC.InitialBoxPosition1
                        : PW < LPC.PerspectiveRatio1
                        ? LPC.InitialBoxPosition2
                        : LPC.InitialBoxPosition3
                    }
                    isVisible={false}
                    rotation-x={Tools.ToRadians(LPC.InitialBoxRotationAngle)}
                    scaling-x={PW}
                    scaling-z={PW}
                  >
                    <standardMaterial diffuseColor={LPC.RedColor} />
                    {lightData?.map((light) => (
                      <box
                        key={light.outputId}
                        name={light.outputId}
                        position={new Vector3(-(light.left / 100).toFixed(3), 0, (light.top / 100).toFixed(3))}
                        width={(light.width / 100).toFixed(3)}
                        depth={(light.height / 100).toFixed(3)}
                        height={LPC.BoxHeight}
                        rotation-y={Tools.ToRadians(light.angle)}
                      >
                        <standardMaterial
                          diffuseColor={LIGHT_COLORS[light.outputColor]}
                          specularColor={LIGHT_COLORS[light.outputColor]}
                          emissiveColor={LPC.BlackColor}
                          name={light.outputId}
                        />
                      </box>
                    ))}
                  </box>
                </Scene>
              </Engine>
            </div>
            <div>
              {lightbar.map((data) => (
                <div className="text-white mt-10">{data}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LightBarPreviewComponent;
