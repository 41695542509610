// Calculation Warning modal component
import React, { useState } from "react";

const CalculationWrngModal = ({ closeWarningModal, warningData, standard }) => {
  const [checkValue, setCheck] = useState(false);
  return (
    <>
      <div id="rotationModal" className="m-auto mg-top-4">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <h2 className="modal-title text-white text-uppercase">Warning</h2>
            <div className="desc mb-7 mt-5">
              The following light source(s) will not contribute to cetain zones due to either non-permissible color
              contribution in adjacent zone(Fringe Flux), non-permissible light color in zone or non-permissible color
              in select mode of operation.
            </div>
            <table className="table table-dark table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="header-sticky text-uppercase" style={{ width: "30%" }}>
                    Light Source
                  </th>
                  <th scope="col" className="header-sticky text-uppercase" style={{ width: "15%" }}>
                    Zone
                  </th>
                  <th scope="col" className="header-sticky text-uppercase" style={{ width: "55%" }}>
                    Reason
                  </th>
                </tr>
              </thead>
              <tbody>
                {warningData.map((data) => (
                  <tr key={warningData?.id}>
                    <td style={{ width: "30%" }}>{data?.lightSource}</td>
                    <td style={{ width: "15%" }} className="text-capitalize">
                      {data?.zone}
                    </td>
                    <td style={{ width: "55%" }}>{data?.reason}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="desc mt-7 mb-3">
              <a
                href="https://www.whelen.com/faqs/"
                rel="noreferrer"
                target="_blank"
                style={{ color: "cornflowerblue" }}
              >
                Learn more in the FAQ
              </a>
            </div>
            <div className="desc mb-3">
              Please refer
              <span>{` ${standard} `}</span>
              for details
            </div>
            <div className="desc mb-6 warning-selection-checkbox">
              <input
                type="checkbox"
                className="checkmark"
                value={checkValue}
                onChange={(e) => {
                  setCheck(e.target.checked);
                }}
              />
              <span>Print the list of Non-Permissible Color Light Source along with Zone value and Reason.</span>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-red"
                onClick={() => {
                  closeWarningModal(checkValue);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculationWrngModal;
