/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";

import obstruct from "../../../assets/images/obstruct.png";
import unchecked from "../../../assets/images/white.png";
import checked from "../../../assets/images/squre-round.png";

const ObstructModal = ({ closeAbstructModal, rowData }) => {
  const [zoneA, setZoneA] = useState(false);
  const [zoneB, setZoneB] = useState(false);
  const [zoneC, setZoneC] = useState(false);
  const [zoneD, setZoneD] = useState(false);
  const [zone, setZone] = useState([]);

  useEffect(() => {
    setZoneA(rowData?.obstruct?.includes("A") || false);
    setZoneB(rowData?.obstruct?.includes("B") || false);
    setZoneC(rowData?.obstruct?.includes("C") || false);
    setZoneD(rowData?.obstruct?.includes("D") || false);
    const zn = rowData.displayId.toString().split("-");
    if (zn.length === 2) {
      setZone(zn[1]);
    }
    if (zn.length === 3) {
      setZone(`${zn[1]} ${zn[2]}`);
    }
  }, []);

  const chooseObstruction = () => {
    closeAbstructModal({ zoneA, zoneB, zoneC, zoneD });
  };

  const closeAbsModel = () => {
    closeAbstructModal();
  };

  // placeholder to solve eslint error
  const handleClick = () => {};

  return (
    <>
      <div id="obstructModal" className="m-auto obstarctlight">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeAbsModel}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h2 className="modal-title text-white text-uppercase">Choose zones that obstruct this light source</h2>

            <div className="current-selection d-flex">
              <p className="text-capitalize" style={{ minWidth: "24%" }}>
                Current Zone:
                {` ${zone}`}
              </p>
              <p className="mr-0">
                Light Source:
                {` ${rowData.sDisplayName}`}
              </p>
            </div>

            <div className="obstruct-zone">
              <div className="d-flex align-items-center justify-content-center">
                {zoneB ? (
                  <img
                    src={checked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setZoneB(!zoneB)}
                    onKeyDown={handleClick}
                  />
                ) : (
                  <img
                    src={unchecked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setZoneB(!zoneB)}
                    onKeyDown={handleClick}
                  />
                )}
                <p className="m-0">Zone B</p>
              </div>
              <div className="d-flex align-items-center text-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                  {zoneA ? (
                    <img
                      src={checked}
                      className="img-fluid cursor-pt mr-2 check-height"
                      alt=""
                      onClick={() => setZoneA(!zoneA)}
                      onKeyDown={handleClick}
                    />
                  ) : (
                    <img
                      src={unchecked}
                      className="img-fluid cursor-pt mr-2 check-height"
                      alt=""
                      onClick={() => setZoneA(!zoneA)}
                      onKeyDown={handleClick}
                    />
                  )}
                  <p className="m-0">Zone A</p>
                </div>
                <img src={obstruct} className="img-fluid obstruct-img" alt="" />
                <div className="d-flex align-items-center justify-content-center">
                  {zoneC ? (
                    <img
                      src={checked}
                      className="img-fluid cursor-pt mr-2 check-height"
                      alt=""
                      onClick={() => setZoneC(!zoneC)}
                      onKeyDown={handleClick}
                    />
                  ) : (
                    <img
                      src={unchecked}
                      className="img-fluid cursor-pt mr-2 check-height"
                      alt=""
                      onClick={() => setZoneC(!zoneC)}
                      onKeyDown={handleClick}
                    />
                  )}
                  <p className="m-0">Zone C</p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                {zoneD ? (
                  <img
                    src={checked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setZoneD(!zoneD)}
                    onKeyDown={handleClick}
                  />
                ) : (
                  <img
                    src={unchecked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setZoneD(!zoneD)}
                    onKeyDown={handleClick}
                  />
                )}
                <p className="m-0">Zone D</p>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-red" onClick={chooseObstruction}>
                Choose
                <br />
                obstructions
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ObstructModal;
