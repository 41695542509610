// component for display zone lower section
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import ZoneCards from "./ZonesCard";
import helper from "../../../helper/helper";

const ZoneLowerSection = ({
  zone,
  setObstruct,
  onChangeQuantity,
  onChangeBlockIntensity,
  onChangeCallIntensity,
  deleteLightsFromZone,
  zoneLightsData,
  setRotation,
  isOntario,
  isMirrorToZoneB,
  isMirrorToZoneD,
}) => (
  <div className="lower-zone">
    <div className="d-flex justify-content-between align-items-center">
      <p className="text-uppercase">Lower</p>
      <p className="d-flex justify-content-between">
        <span>Current Draw</span>

        <span>
          Peak:
          {` ${helper.getCurrentMax(zoneLightsData, zone, "lower")}`}
        </span>
        <span>
          Avg:
          {` ${helper.getCurrentAverage(zoneLightsData, zone, "lower")}`}
        </span>
      </p>
    </div>
    <div className="details-block style">
      <Droppable droppableId={`zoneContentMix-${zone}-lower`} type="TASK">
        {(provided, snapshot) => (
          <div
            className="section-scroll"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {(zone === "A" && zoneLightsData.zoneADataLower.length) ||
            (zone === "B" && zoneLightsData.zoneBDataLower.length) ||
            (zone === "C" && zoneLightsData.zoneCDataLower.length) ||
            (zone === "D" && zoneLightsData.zoneDDataLower.length) ? (
              <div className="row sub-details sticky">
                <div className="col-md-3  m-0">
                  <p>Source</p>
                </div>
                <div className="col-md-9 light-width">
                  <div className="row justify-content-between custom-width">
                    <p>Qty</p>
                    <p>Calling Intensity</p>
                    <p>Blocking Intensity</p>
                    <p>Obstructed</p>
                    <p>Rotation</p>
                    <p style={{ width: "10px" }} />
                  </div>
                </div>
              </div>
            ) : null}
            {zone === "A" && zoneLightsData.zoneADataLower && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneADataLower}
                onChangeQuantity={onChangeQuantity}
                zone="zoneADataLower"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "B" && zoneLightsData.zoneBDataLower && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneBDataLower}
                onChangeQuantity={onChangeQuantity}
                zone="zoneBDataLower"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "C" && zoneLightsData.zoneCDataLower && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneCDataLower}
                onChangeQuantity={onChangeQuantity}
                zone="zoneCDataLower"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "D" && zoneLightsData.zoneDDataLower && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneDDataLower}
                onChangeQuantity={onChangeQuantity}
                zone="zoneDDataLower"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
          </div>
        )}
      </Droppable>
    </div>
  </div>
);

export default ZoneLowerSection;
