/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// Confirmation modal component
import React, { useState } from "react";
import unchecked from "../../assets/images/white.png";
import checked from "../../assets/images/squre-round.png";

const ConfirmationModal = ({ closeConfirmationModal, message }) => {
  const [isChecked, setIsChecked] = useState(false);

  const onClickOk = () => {
    closeConfirmationModal(message === "fromResultPage" ? isChecked : true);
  };
  const closeModal = () => {
    closeConfirmationModal(false);
  };
  // placeholder to solve eslint error
  const handleClick = () => {};
  return (
    <>
      <div id="saveas-modal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h4 className="modal-title text-white text-uppercase">
              {message === "fromResultPage" ? "Print Document" : "Delete Light"}
            </h4>
            {message === "fromResultPage" ? (
              <div className="d-flex align-items-center justify-content-center mt-6 mb-6">
                {isChecked ? (
                  <img
                    src={checked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setIsChecked(!isChecked)}
                    onKeyDown={handleClick}
                  />
                ) : (
                  <img
                    src={unchecked}
                    className="img-fluid cursor-pt mr-2 check-height"
                    alt=""
                    onClick={() => setIsChecked(!isChecked)}
                    onKeyDown={handleClick}
                  />
                )}
                <p className="mb-2 text-uppercase">Ignore failed zones</p>
              </div>
            ) : (
              <div className="rotation-option" style={{ marginTop: "30px" }}>
                <div className="form-group">
                  <div style={{ color: "white" }}>{message}</div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-red" onClick={onClickOk} style={{ width: "150px" }}>
                {message === "fromResultPage" ? "Print" : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
