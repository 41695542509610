// Precalculation Warning modal component
import React, { useEffect, useState } from "react";
import ZoneLightSelection from "./ZoneLightSelection";
import { SPECS_1900 } from "../../../services/Constant";

const PreCalIndividualLightSelectionModal = ({
  zoneLightsData,
  isGreaterThan,
  saveDataAndPerformCalculation,
  closeLightSelectionModal,
  standardName,
}) => {
  const [excludedLights, setExcludedLights] = useState([]);
  const [allZoneLights, setallZoneLights] = useState(zoneLightsData);
  const [excludedLightsDuoTrio, setExcludedLightsDuoTrio] = useState([]);

  useEffect(() => {
    const zoneLights = [];
    Object.keys(zoneLightsData).forEach((zone) => {
      zoneLights[zone] = zoneLightsData[zone].map((lightData) => ({
        ...lightData,
        color1Selected: true,
        color2Selected: true,
        color3Selected: true,
      }));
    });
    setallZoneLights(zoneLights);
  }, []);
  const prepareRequestData = (lightId, color, zoneLightData, action) => {
    if (action === "remove") {
      if (zoneLightData.color1 && (zoneLightData.color2 || zoneLightData.color3)) {
        const lightIndex = excludedLightsDuoTrio.findIndex(
          (excludedLightDuoTrio) => excludedLightDuoTrio.lightId === lightId
        );
        excludedLightsDuoTrio.splice(lightIndex, 1);
        setExcludedLightsDuoTrio(excludedLightsDuoTrio);
      } else {
        excludedLights.splice(excludedLights.indexOf(lightId), 1);
        setExcludedLights(excludedLights);
      }
    }
    if (action === "add") {
      if (
        zoneLightData.color1 &&
        (zoneLightData.color2 || zoneLightData.color3) &&
        excludedLightsDuoTrio.findIndex((excludedLightDuoTrio) => excludedLightDuoTrio.lightId === lightId) === -1
      ) {
        const lights = [...excludedLightsDuoTrio, { [lightId]: color }];
        setExcludedLightsDuoTrio(lights);
      } else if (excludedLights.indexOf(lightId) === -1) {
        const lights = [...excludedLights, lightId];
        setExcludedLights(lights);
      }
    }
  };
  const checkboxEvent = (event, zoneDataArrayIndex, zoneArea, lightSourceId, color = null, colorIndexName = null) => {
    const lightId = lightSourceId;
    const zonLights = { ...allZoneLights };
    if (event.target.checked) {
      prepareRequestData(lightId, color, zonLights[zoneArea][zoneDataArrayIndex], "remove");
    }
    if (!event.target.checked) {
      prepareRequestData(lightId, color, zonLights[zoneArea][zoneDataArrayIndex], "add");
    }
    zonLights[zoneArea][zoneDataArrayIndex][colorIndexName] = event.target.isChecked;
    setallZoneLights(zonLights);
  };
  return (
    <>
      <div id="preCalLightIncludeModal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={() => closeLightSelectionModal()}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h3 className="modal-title text-white"> Lightsource to include in the calculation </h3>
            {isGreaterThan || standardName === SPECS_1900.CH10 || standardName === SPECS_1900.CH31 ? (
              <div className="precal-light-include-content">
                <div className="row gutter-5">
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone A</h3>
                      <div className="zone-area section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneADataUpper}
                          level="Upper"
                          zoneArea="zoneADataUpper"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                      <div className="one-area section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneADataLower}
                          level="Lower"
                          zoneArea="zoneADataLower"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone B</h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneBDataUpper}
                          level="Upper"
                          zoneArea="zoneBDataUpper"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneBDataLower}
                          level="Lower"
                          zoneArea="zoneBDataLower"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone C</h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneCDataUpper}
                          level="Upper"
                          zoneArea="zoneCDataUpper"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneCDataLower}
                          level="Lower"
                          zoneArea="zoneCDataLower"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone D</h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneDDataUpper}
                          level="Upper"
                          zoneArea="zoneDDataUpper"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneDDataLower}
                          level="Lower"
                          zoneArea="zoneDDataLower"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="precal-light-include-content">
                <div className="row">
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone A</h3>
                      <div className="zone-area section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneAData}
                          level=""
                          zoneArea="zoneAData"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone B</h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneBData}
                          level=""
                          zoneArea="zoneBData"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone C </h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneCData}
                          level=""
                          zoneArea="zoneCData"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 mb-5">
                    <div className="zone-light-section">
                      <h3 className="text-uppercase text-white pl-2">Zone D</h3>
                      <div className="section-scroll">
                        <ZoneLightSelection
                          zoneData={allZoneLights.zoneDData}
                          level=""
                          zoneArea="zoneDData"
                          checkboxEvent={checkboxEvent}
                          standardName={standardName}
                          excludeLevel="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="action-btn">
              <button
                type="button"
                onClick={() => closeLightSelectionModal()}
                className="btn btn-red text-white text-uppercase"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => saveDataAndPerformCalculation(excludedLights, excludedLightsDuoTrio)}
                className="btn btn-red text-white text-uppercase"
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreCalIndividualLightSelectionModal;
