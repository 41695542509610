import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";

// import React, { useEffect, useState } from "react";
import authService from "../services/AuthService";

const Logout = () => {
  const history = useHistory();

  // Redirect app to login page and clean the local storage
  const reset = () => {
    history.replace("/login");
    window.onpopstate = () => {
      history.go(1);
    };
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("loggedIn", JSON.stringify(false));
    window.location.reload();
  };

  // User clicks on Log out button
  const onSignOut = () => {
    authService
      .logout()
      .then(() => {
        reset();
      })
      .catch(() => {
        reset();
      });
  };

  useEffect(() => {
    onSignOut();
    window.onpopstate = () => null;
  }, []);

  return <></>;
};
export default Logout;
