// component for display zone full section without upper/lower
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import ZoneCards from "./ZonesCard";
import helper from "../../../helper/helper";

const ZoneFullSection = ({
  zone,
  setObstruct,
  onChangeQuantity,
  onChangeBlockIntensity,
  onChangeCallIntensity,
  deleteLightsFromZone,
  zoneLightsData,
  setRotation,
  isOntario,
  isMirrorToZoneB,
  isMirrorToZoneD,
  isFullSection,
}) => (
  <>
    <div className="d-flex justify-content-between align-items-center">
      <p className="text-uppercase" style={{ visibility: "hidden" }} />
      <p className="d-flex justify-content-between">
        <span>Current Draw</span>
        <span>
          Peak:
          {` ${helper.getCurrentMax(zoneLightsData, zone, "single")}`}
        </span>
        <span>
          Avg:
          {` ${helper.getCurrentAverage(zoneLightsData, zone, "single")}`}
        </span>
      </p>
    </div>
    <div className="details-block-single style" style={{ height: isFullSection ? "510px" : "250px" }}>
      <Droppable droppableId={`zoneContent-${zone}`} type="TASK">
        {(provided, snapshot) => (
          <div
            className="section-scroll"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {(zone === "A" && zoneLightsData.zoneAData.length) ||
            (zone === "B" && zoneLightsData.zoneBData.length) ||
            (zone === "C" && zoneLightsData.zoneCData.length) ||
            (zone === "D" && zoneLightsData.zoneDData.length) ? (
              <div className="row sub-details sticky">
                <div className="col-md-3 m-0">
                  <p>Source</p>
                </div>
                <div className="col-md-9 light-width">
                  <div className="row justify-content-between custom-width">
                    <p>Qty</p>
                    <p>Calling Intensity</p>
                    <p>Blocking Intensity</p>
                    <p>Obstructed</p>
                    <p>Rotation</p>
                    <p style={{ width: "10px" }} />
                  </div>
                </div>
              </div>
            ) : null}
            {zone === "A" && zoneLightsData.zoneAData && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneAData}
                onChangeQuantity={onChangeQuantity}
                zone="zoneAData"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "B" && zoneLightsData.zoneBData && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneBData}
                onChangeQuantity={onChangeQuantity}
                zone="zoneBData"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "C" && zoneLightsData.zoneCData && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneCData}
                onChangeQuantity={onChangeQuantity}
                zone="zoneCData"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "D" && zoneLightsData.zoneDData && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneDData}
                onChangeQuantity={onChangeQuantity}
                zone="zoneDData"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
          </div>
        )}
      </Droppable>
    </div>
  </>
);

export default ZoneFullSection;
