// save as modal component
import React, { useState } from "react";

const SaveAsModel = ({ closeSaveAsModal, saveAsConfigData, loading }) => {
  const [configName, setConfigName] = useState("");
  const [configError, setConfigError] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  // used for handle change event of the text box
  const handleChangeConfigName = (e) => {
    e.preventDefault();
    setConfigName(e.target.value);
  };

  // used for call parent function for the save as
  const onClickSaveAs = () => {
    if (configName === "") {
      setConfigError(true);
    } else {
      saveAsConfigData(configName);
      setIsRequested(true);
    }
  };

  return (
    <>
      <div id="saveas-modal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeSaveAsModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h4 className="modal-title text-white text-uppercase">Save As Configuration</h4>
            <div className="rotation-option" style={{ marginTop: "30px" }}>
              <div className="form-group" style={{ width: "100%" }}>
                <label>Config Name</label>
                <input
                  type="text"
                  value={configName}
                  name="configname"
                  className="form-control"
                  autoComplete="off"
                  maxLength={40}
                  onChange={handleChangeConfigName}
                />
                {configError && <div className="invalid-feedback d-flex">Config Name is required</div>}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-red" onClick={onClickSaveAs}>
                {loading && isRequested ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                Save As
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaveAsModel;
