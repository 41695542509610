// Precalculation Warning modal component
import React, { useEffect } from "react";

const PreCalculationWrngModal = ({ continueCalculation }) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        continueCalculation(false);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <>
      <div id="preCalModal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={() => continueCalculation("Close")}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h2 className="modal-title text-white text-uppercase">Warning</h2>
            <div className="desc mb-5 mt-5">Excluded and/or inactive lighthead(s) found in this configuration</div>
            <table className="table table-dark table-bordered">
              <tbody>
                <tr>
                  <td className="ver-al pl-0">
                    <button
                      type="button"
                      className="btn btn-red text-uppercase mt-0"
                      onClick={() => continueCalculation(true)}
                    >
                      Use Historic Data
                    </button>
                  </td>
                  <td className="ver-al">
                    Information from the excluded and/or inactive lighthead(s) will be used in the calculation, however
                    it may be inaccurate.
                  </td>
                </tr>
                <tr>
                  <td className="ver-al pl-0">
                    <button
                      type="button"
                      className="btn btn-red text-uppercase mt-0"
                      onClick={() => continueCalculation(false)}
                    >
                      Don’t Use Historic Data
                    </button>
                  </td>
                  <td className="ver-al">
                    The excluded and/or inactive lighthead(s) will not be considered during the calculation.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreCalculationWrngModal;
