// Session timer modal component
import React from "react";
import config from "../../config/config";

class SessionTimeoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: config.SESSION_NOTIFICATION_TIMEOUT };
    this.endTimer = null;
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.endTimer);
  }

  closeModal = () => {
    clearTimeout(this.endTimer);
    const { count } = this.state;
    const { onCancleSession } = this.props;
    onCancleSession(count);
  };

  startTimer = () => {
    const { count } = this.state;
    const { onCancleSession } = this.props;
    let value = count;
    this.endTimer = setTimeout(() => {
      if (count > 0) {
        value -= 1;
        this.setState({ count: value });
        this.startTimer();
      }
      if (count === 0) {
        onCancleSession(0);
      }
    }, 1000);
  };

  onClickOk = () => {
    clearTimeout(this.endTimer);
    const { onResetSession } = this.props;
    onResetSession();
  };

  render() {
    const { count } = this.state;

    return (
      <>
        <div id="session-modal" className="m-auto">
          <div className="modal-dialog m-0">
            <div className="modal-content">
              <div className="close-icon text-right">
                <button type="button" className="close" data-dismiss="modal" onClick={this.closeModal}>
                  <span aria-hidden="true">
                    <i className="fa fa-times text-red mt-2" />
                  </span>
                </button>
              </div>
              <h4 className="modal-title text-white text-uppercase">Session Expiration Notification</h4>
              <div className="notification" style={{ marginTop: "30px" }}>
                <div className="form-group">
                  <div style={{ color: "white" }}>
                    Your session will expire in &nbsp;
                    <b>{count}</b>
                    &nbsp; seconds.
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-red" onClick={this.onClickOk}>
                  Refresh Session
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SessionTimeoutModal;
