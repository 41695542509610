const pdfScriptFooterPaginationMoz = (printWarning) => `window.onload = function() {
    // firefox
    // commented pagination part for now.
    let contentHeight = document.querySelector(".header-details").clientHeight + document.querySelector(".results-data").clientHeight + 80 + 60;
    let numberOfPage = 1;
    let totalNumberOfPage = 1;
    let numberOfWarningPage = 0;
    const PAGE_HEIGHT_ = 1510;
    const PAGE_HEIGHT = 1490;
    const LIGHT_PAGE_BREAK_HEIGHT = 1470;
    const WARNING_PAGE_HEIGHT = 1450;
    const WARNING_PAGE_HEIGHT_ = 1350;
    const LIGHTBAR_PAGE_BREAK_HEIGHT = 1460;
    const FOOTER_HEIGHT = 1480;
    const printElement = document.getElementById("light-lightbar")
    let rowHeight = contentHeight;
    let pageBreak = 1;
    document.querySelectorAll(".zone-table tr").forEach((row, index) => {
        rowHeight += row.clientHeight;
        // console.log(rowHeight, LIGHT_PAGE_BREAK_HEIGHT * pageBreak);
        const lightRowInsert = rowHeight > LIGHT_PAGE_BREAK_HEIGHT * pageBreak;
        const difference = LIGHT_PAGE_BREAK_HEIGHT * pageBreak - rowHeight;
        if (lightRowInsert || difference < 15) {
            const paginationSpace = document.querySelector(".zone-table").insertRow(index);
            // paginationSpace.innerHTML = "<td colspan='9' class='light-pagination-" + pageBreak + "'> Page " + pageBreak + " of " + totalNumberOfPage + " </td>";
            // if (document.querySelector(".zone-table .page-break").length < index + 1) {
                const tableHeading = document.querySelector(".zone-table").insertRow(index);
                tableHeading.classList.add("page-break-header");
                const headingEntry = document.querySelector(".zone-table tr").innerHTML;
                tableHeading.innerHTML = headingEntry;
                pageBreak++;
            // }
        }
        if (document.querySelector(".light-pagination")) {
            // document.querySelector(".light-pagination").innerHTML = "Page " + pageBreak + " of " + totalNumberOfPage
        }
    });
    if (printElement) {
        printElement.classList.add("temp-class-for-height");
        const height = printElement.clientHeight;
        numberOfPage = Math.ceil(height / PAGE_HEIGHT_);
        console.log(numberOfPage, "numberOfPage printelement ");
    }
    if (${printWarning} === true) {
        numberOfWarningPage = 1;
        const header = document.querySelector(".header-details").clientHeight + 80;
        const warningData = document.getElementById("warningContent").clientHeight;
        const printWarningContent = header + warningData;
        const warningPage = Math.ceil(printWarningContent / WARNING_PAGE_HEIGHT_);
        if (warningPage > 0) {
            numberOfWarningPage = warningPage;
        }
    }
    totalNumberOfPage = numberOfPage + numberOfWarningPage;
    let pageBreakOuts = 0;
    let lightbarheight = [];
    let existingContentHeight = contentHeight + document.querySelector(".zone-table-data").clientHeight;
    if (document.querySelectorAll("[class*=lightbar-counter-").length) {
        document.querySelectorAll("[class*=lightbar-counter-").forEach((lightbar, lightbarIndex) => {
            if (pageBreak) {
                existingContentHeight += lightbar.clientHeight;
                if (existingContentHeight > LIGHTBAR_PAGE_BREAK_HEIGHT * pageBreak) {
                    if (document.querySelector(".lightbar-counter-" + (lightbarIndex - 1))) {
                        // document.querySelector(".lightbar-page-" + (lightbarIndex - 1)).innerHTML = "Page " + pageBreak + " of "  + totalNumberOfPage;
                        document.querySelector(".lightbar-page-" + (lightbarIndex - 1)).setAttribute("extraSpace", "40");
                        document.querySelector(".lightbar-page-" + (lightbarIndex - 1)).setAttribute("pagenumber", pageBreak);
                        document.querySelector(".lightbar-counter-" + (lightbarIndex - 1)).setAttribute("style", "page-break-after: always; break-after: always");
                        if (document.querySelectorAll("[class*=lightbar-counter-").length - 1 !== lightbarIndex) {
                            pageBreak++;
                        }
                    }
                    
                    
                    lightbarheight.push(existingContentHeight - LIGHTBAR_PAGE_BREAK_HEIGHT * pageBreak); // add remaining space
                    // }
                }
                if (document.querySelectorAll("[class*=lightbar-counter-").length === (lightbarIndex + 1)) {
                    if(document.querySelector(".light-pagination")) {
                     //  document.querySelector(".light-pagination").innerHTML = "Page " + pageBreak + " of " + totalNumberOfPage
                    } else {
                     //  document.querySelector(".lightbar-page").innerHTML = "Page " + pageBreak + " of " + totalNumberOfPage
                    }
                }
            }                   
        });
    }
    let coveredPageInLight = 1;
    document.querySelectorAll("[class*=light-pagination-]").forEach((lightpagination, index) => {
        let pageHeight = 1560;
        let placement = 1560 * (index + 1);
        coveredPageInLight = index + 1;
        lightpagination.setAttribute(
          "style",
          "height: 0; float: right; right: -30px; font-weight: bold; font-size: 12px; position: absolute; overflow: visible; top: " + placement + "px;"
        );
    });
    const lightbars = document.querySelectorAll("[class*=lightbar-page-]");
    lightbars.forEach((lightpagination, index) => {
        if (lightpagination.innerHTML !== "") {
            let top = 0;
            if (lightbars[index + 1]) {
                const className = lightbars[index + 1].className;
                if (document.querySelector("." + className).offsetTop < 1490) {
                    top = 30;
                }
                if (lightpagination.getAttribute("extraspace") && lightpagination.getAttribute("pagenumber")) {
                    let top = 1490 * parseInt(lightpagination.getAttribute("pagenumber")) - lightpagination.offsetTop + lightpagination.clientHeight;
                }
            } else {
                const className = lightbars[index].className;
                if (document.querySelector("." + className).offsetTop < 1490) {
                    top = 30;
                }
            }
            lightpagination.setAttribute(
            "style",
            "height: 0; float: right; right: -30px; font-weight:bold; font-size: 12px; position: relative; overflow: visible; z-index: 2; top: " + top + "px;"
            );
        }
    });
    if (document.querySelector(".warningRows")) {
        pageBreak++;
        let warningContent = document.querySelector(".header-details").clientHeight + 60 + 80 + 20;
        let warningPageCount = 1;
        document.querySelector(".warningRows").childNodes.forEach((row, index) => {
            warningContent += row.clientHeight;
            if (warningContent > (1360 * warningPageCount) + (warningPageCount * 15)) {
                warningPageCount++;
                let pageSpan = document.createElement("DIV");
                pageSpan.className = "warning-page-count";
                // pageSpan.innerText = "Page " + pageBreak + " of " + totalNumberOfPage;
                row.after(pageSpan);
                pageBreak++;
            }
        })
        if (document.querySelector(".warning-pagination")) {
           // document.querySelector(".warning-pagination").innerHTML = "Page " + pageBreak + " of " + totalNumberOfPage;
        }
        if (document.querySelector(".warning-page-count")) {
           // document.querySelector("style").textContent += "@media print { .pdf .warning-page-count { break-after: always; height: 0; float: right; position: relative; top: 20px; font-weight: bold; font-size: 12px; right: -35px;} }";
        }
    }
    
    let lightPosition = 0;
    let warningPosition = 0;
    let extraOff = 10;
    if ( document.querySelectorAll("[class*=light-paginate-]")) {
        extraOff = document.querySelectorAll("[class*=light-paginate-]").length * 90;
    }
    
    function getChildPositionFooter(childElement) {
        var parentElement = childElement.parentNode;
        var childRect = childElement.getBoundingClientRect();
        var parentRect = parentElement.getBoundingClientRect();
        var position = {
          left: childRect.left - parentRect.left,
          top: childRect.top - parentRect.top
        };
        return position;
      }
      // Get the child element
      var childElement = document.querySelector('.footer-1');
      const footerPos1 = getChildPositionFooter(childElement).top;

    let footerPosition = FOOTER_HEIGHT * numberOfPage - footerPos1;
    if (footerPosition < 0) {
        footerPosition -= footerPosition;
    }
    // handles if minor footer cutting at corners
    document.querySelectorAll(".zone-table tr").forEach((row) => {
        footerPosition -= 0.6;
    })
    let sameHeight = false;
    if (parseInt(document.getElementById("light-lightbar").getBoundingClientRect().height + footerPosition) == FOOTER_HEIGHT * numberOfPage) {
        footerPosition -= 80; // exceptional if footer is placing exact nearly at bottom
        sameHeight = true;
    }
    if (FOOTER_HEIGHT * numberOfPage - parseInt(document.getElementById("light-lightbar").getBoundingClientRect().height + footerPosition) < 100) {
        footerPosition -= 15; // exceptional if footer is placing exact nearly at bottom
    }
    if (sameHeight) {
        document.querySelector("style").textContent += "@media print { .pdf .footer-1 { position: unset; height: 0; display: block!important;} }";
    } else {
        document.querySelector("style").textContent += "@media print { .pdf .footer-1 { top: " + footerPosition + "px; height: 0; display: block!important;} }";
        //document.querySelector("style").textContent += "@media print { .pdf .footer-1 span { height: 0; display: block!important; float: right; position: relative; right: 50px; font-size: 12px;font-weight: bold;} }";
    }
    // footer 2 calc....

    if (document.querySelector(".footer-2")) {
        const footerHeight = 50;
        let footerPosition2 = 0;
        function getChildPosition(childElement) {
            var parentElement = childElement.parentNode;
            var childRect = childElement.getBoundingClientRect();
            var parentRect = parentElement.getBoundingClientRect();
            var position = {
              left: childRect.left - parentRect.left,
              top: childRect.top - parentRect.top,
              parentheight: parentRect.height
            };
            return position;
          }
          // Get the child element
          var childElement = document.querySelector('.footer-2');
          const footerPlace = getChildPosition(childElement);
          const parentheight = footerPlace.parentheight;
          const footerPos = footerPlace.top;
          let extraSpacingDueToMultiPage = 0;
          if (numberOfWarningPage > 1 && footerPos < 400) { // content ending in half of page
            extraSpacingDueToMultiPage = numberOfWarningPage * 40;
          }
          footerPosition2 = WARNING_PAGE_HEIGHT * numberOfWarningPage - footerPos;
          if (footerPosition2 > 200) {
            let rowsPadding = document.querySelector(".warningRows").children.length * 3.5;
            footerPosition2 -= rowsPadding; 
          }
          if (numberOfWarningPage > 3) {
            footerPosition2 += 30 * numberOfWarningPage;
          }
          if (footerPosition2 + parentheight > WARNING_PAGE_HEIGHT * numberOfWarningPage) {
            footerPosition2 -= footerPosition2 + parentheight - WARNING_PAGE_HEIGHT * numberOfWarningPage;
          }

        document.querySelector("style").textContent += "@media print { .pdf .footer-2 { top: " + footerPosition2 + "px; height: 0; display: block!important;} }";
        // document.querySelector("style").textContent += "@media print { .pdf .footer-2 span { height: 0; display: block!important; float: right; position: relative; right: 50px; font-size: 12px;font-weight: bold;} }";
    }
    document.querySelector("style").textContent += ".pdf .intensity-table-new td { max-width: 55px!important; }";
    if (numberOfPage === 1 && document.querySelector('.custom-page-break') && ${printWarning} !== true) {
        document.querySelector('.custom-page-break').style.breakAfter = 'avoid'; // firefox
        document.querySelector('.custom-page-break').style.pageBreakAfter = 'avoid';
    }
    
    setTimeout(() => window.print(), 1000) }`;

export default pdfScriptFooterPaginationMoz;
