// Share configuration modal component
import React, { useState } from "react";
import config from "../../../config/config";

const { WARNING_MESSAGE } = config;
const ShareConfiguration = ({ closeShareConfigModal, shareConfigData, loading }) => {
  const [emailId, setEmail] = useState("");
  const [isValidEmail, setValidEmail] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const validateEmail = (email) =>
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  // used for handle change event of the emailId text box
  const handleChangeEmailAddress = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    if (!isTouched) {
      setTouched(true);
    }
    setValidEmail(validateEmail(e.target.value));
  };

  // used for call parent function for the save as
  const onClickShareConfiguration = () => {
    if (!loading) {
      shareConfigData(emailId);
    }
  };
  return (
    <>
      <div id="shareConfiguration-modal" className="m-auto">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={closeShareConfigModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h4 className="modal-title text-white text-uppercase">Share Configuration</h4>
            <div className="rotation-option" style={{ marginTop: "30px" }}>
              <div className="form-group" style={{ width: "100%" }}>
                <label>Destination Email Address</label>
                <input
                  type="text"
                  name="email"
                  value={emailId}
                  className={`form-control ${isTouched && !isValidEmail ? "is-invalid" : ""} user-input`}
                  autoComplete="off"
                  spellCheck="false"
                  maxLength={128}
                  onChange={handleChangeEmailAddress}
                />
                {isTouched && !isValidEmail && (
                  <div className="invalid-feedback d-flex">
                    {emailId.length ? WARNING_MESSAGE.EMAILID_INVALID : WARNING_MESSAGE.EMAILID_REQUIRED}
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-red"
                style={{ marginRight: "20px" }}
                onClick={onClickShareConfiguration}
                disabled={!isTouched || !isValidEmail || loading}
              >
                Share
                {loading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareConfiguration;
