// Footer Component
import React from "react";

export default () => {
  // Get Year Data
  const getYear = () => new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="text-center">
            Copyright &copy;
            {getYear()}
            <a href="https://www.whelen.com/" rel="noreferrer" target="_blank" className="d-inline-block mx-2">
              Whelen Engineering.
            </a>
            All rights reserved.
          </div>
        </div>
      </footer>
    </>
  );
};
