// Frame Components
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
// Ui + style Components
import "./assets/scss/main.scss";

import "bootstrap/dist/js/bootstrap.js";
// Api Components
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const { detect } = require("detect-browser");

const browser = detect();

if (browser.name === "firefox") {
  import("./assets/scss/pages/_pdf_moz.scss");
} else {
  import("./assets/scss/pages/_pdf.scss");
}

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
