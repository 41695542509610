// Config list modal component
import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import configService from "../../../services/ConfigService";
import commonUtiles from "../../../services/CommonUtiles";
import Helpers from "../../../helper/helper";

const reactStringReplace = require("react-string-replace");

const ConfigListModal = ({ closeConfigListModal }) => {
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchedVal, setSearchedVal] = useState("");
  const [pageSize, setPageSize] = useState(Helpers.getPageSizes()[1]);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState("disabled");
  const [isNextBtnActive, setIsNextBtnActive] = useState("");
  const [prePageTokens, setPrePageTokens] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");

  const sortInside = {
    column: "configName",
    direction: "asc",
  };
  const [sort, setSort] = useState(sortInside);
  const [configList, setConfigList] = useState([]);

  const viewConfigData = (e, configDetails) => {
    e.preventDefault();
    const confDetail = JSON.parse(JSON.stringify(configDetails));
    const request = {};
    request.configDetails = confDetail;
    request.vehicleSize = confDetail.vehicleSize;
    request.stdName = configDetails.standardName;
    configService.setConfigToSessionStorage(request);
    closeConfigListModal(request.configDetails);
  };

  const CloseModal = () => {
    closeConfigListModal();
  };
  // Function use for sorting table data in ascending and descending order.
  const onSort = (column, data, listing) => {
    const direction = !listing ? (sort.direction === "asc" ? "desc" : "asc") : "asc";
    const allEqual = data.every((item) => data[0][column] === item[column]);
    let sortedData;
    if (allEqual) {
      sortedData = data.reverse();
    } else {
      sortedData = data.sort((a, b) => commonUtiles.compareValues(a, b, column, direction));
      if (direction === "desc") {
        sortedData.reverse();
      }
    }
    setConfigList(sortedData);
    setSort({
      column,
      direction,
    });
    return sortedData;
  };

  // Method use to get configData' data
  const getConfigurations = (pageToken, filterVal, pageVal) => {
    setConfigList([]);
    setLoading(true);
    configService
      .getConfigurations(pageVal.value, pageToken, filterVal)
      .then((response) => {
        setLoading(false);
        onSort("configName", response.data.configData, true);
        if (response.data.lastEvaluatedKey && response.data.configData.length) {
          setNextPageToken(response.data.lastEvaluatedKey);
          setIsNextBtnActive("");
        } else {
          setIsNextBtnActive("disabled");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const onReset = () => {
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    setSearchVal("");
    setSearchedVal("");
    setShowReset(false);
  };

  // Function is used to get config data on page load.
  useEffect(() => {
    getConfigurations("", "", pageSize);
  }, []);

  // Function uses for clear filter data
  const onLoad = () => {
    onReset();
    getConfigurations("", "", pageSize);
  };

  // Function uses to filter configData' data
  const handleSearch = () => {
    if (searchVal) {
      setSort(sortInside);
      setNextPageToken("");
      setSearchedVal(searchVal);
      setIsPrevBtnActive("disabled");
      setPrePageTokens([]);
      getConfigurations("", searchVal, pageSize);
      setShowReset(true);
    }
  };
  // function invoke on Next button click
  const btnNextClick = (e) => {
    e.preventDefault();
    prePageTokens.push(nextPageToken);
    setIsPrevBtnActive("");
    getConfigurations(nextPageToken, searchVal, pageSize);
  };

  // function invoke when Previous button click
  const btnPrevClick = (e) => {
    e.preventDefault();
    if (prePageTokens.length > 1) {
      getConfigurations(prePageTokens[prePageTokens.length - 2], searchVal, pageSize);
      prePageTokens.pop();
    } else {
      prePageTokens.pop();
      getConfigurations("", searchVal, pageSize);
      setIsPrevBtnActive("disabled");
    }
    setIsNextBtnActive("");
  };
  // Enter key handler
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };
  // function use to set filter value
  const updateInputValue = (evt) => {
    setSearchVal(evt.target.value);
    if (evt.target.value) {
      setShowReset(true);
    } else {
      setShowReset(false);
      if (searchVal) {
        onLoad();
      }
    }
  };

  // placeholder to solve eslint error
  const handleClick = () => {};

  // Function uses for clear filter data
  const onResetHandler = (e) => {
    e.preventDefault();
    onLoad();
  };

  // handle onChange event of the dropdown
  const pageChange = (e) => {
    setPageSize(e);
    setPrePageTokens([]);
    setIsPrevBtnActive("disabled");
    setNextPageToken("");
    getConfigurations("", searchVal, e);
  };

  const renderBody = () =>
    configList?.length ? (
      configList.map((config) => (
        <tr key={config.id}>
          <td className="conf-ht">
            <a
              href="#"
              data-dismiss="modal"
              onClick={(e) => {
                viewConfigData(e, config);
              }}
              className="text-white"
            >
              <div className="max-conf-name text-eclips" data-tip={config.configName} data-for={config.configName}>
                {reactStringReplace(config.configName, searchedVal, (match, i) => (
                  <span key={i} style={{ color: "red" }}>
                    {match}
                  </span>
                ))}
              </div>
            </a>
          </td>
          <ReactTooltip
            id={config.configName}
            place="right"
            multiline
            type="light"
            effect="solid"
            className="zone-tooltip"
          />
        </tr>
      ))
    ) : (
      <tr>
        <td>
          <div className="no-records-alert text-center">No Configuration Available</div>
        </td>
      </tr>
    );

  return (
    <div id="configurationListModal" className="m-auto">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="d-flex align-items-center changepass-title mb-0">
            <h2 className="text-white text-uppercase">Configuration List</h2>
            <div className="close-icon text-right mb-9">
              <button type="button" className="close" data-dismiss="modal" onClick={CloseModal}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
          </div>
          <div className="modal-body" style={{ height: "690px", padding: 0, marginTop: "-10px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback" />
                <input
                  type="text"
                  className="form-control"
                  onKeyDown={onKeyDownHandler}
                  value={searchVal}
                  onChange={updateInputValue}
                  placeholder="Search for Name"
                />
                {showReset ? (
                  <i
                    aria-hidden="true"
                    className="fa fa-times close-icon"
                    onClick={onResetHandler}
                    onKeyDown={handleClick}
                    role="button"
                    tabIndex={0}
                  />
                ) : null}
              </div>
            </div>

            <div className="mt-4 table-max">
              <table className="table table-dark table-bordered">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      onClick={() => onSort("configName", configList)}
                      className="header-sticky text-uppercase"
                    >
                      Name
                      <span className={commonUtiles.setArrow(sort, "configName")} />
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td>
                        <div className="mt-5 mb-5">
                          <div className="loader m-auto" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>{renderBody()}</tbody>
                )}
              </table>
            </div>
            {(!isPrevBtnActive || !isNextBtnActive) && !loading ? (
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-end mb-0 py-0 px-0 mt-4">
                  <li>
                    <Select
                      onChange={pageChange}
                      options={Helpers.getPageSizes()}
                      name="role"
                      className="select-xsdd mr-3"
                      autoFocus={false}
                      isSearchable={false}
                      value={pageSize}
                      styles={Helpers.getDropDownStyleForPage()}
                    />
                  </li>
                  {isPrevBtnActive === "disabled" ? (
                    <li className="breadcrumb-item disable" aria-current="page">
                      <div className="disable">Prev</div>
                    </li>
                  ) : (
                    <li className="page-item">
                      <li className="breadcrumb-item" aria-current="page">
                        <a href="#" onClick={btnPrevClick}>
                          Prev
                        </a>
                      </li>
                    </li>
                  )}
                  <div className="pipe">|</div>
                  {isNextBtnActive === "disabled" ? (
                    <li className="breadcrumb-item">
                      <div className="disable">Next</div>
                    </li>
                  ) : (
                    <li className="breadcrumb-item">
                      <a href="#" onClick={btnNextClick}>
                        Next
                      </a>
                    </li>
                  )}
                </ol>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfigListModal;
