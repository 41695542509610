// Light rotation modal component
import React, { useState, useEffect } from "react";
import Select from "react-select";

import carImg from "../../../assets/images/obstruct.png";
import barB from "../../../assets/images/barB.png";
import headB from "../../../assets/images/headB.png";
import Helper from "../../../helper/helper";

const RotationModel = ({ closeRotationModal, rowData }) => {
  const [options, setOptions] = useState([]);
  const [rotation, setRotation] = useState([]);
  const [zone, setZone] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const calculateOptions = (value) => {
    const optionList = [];
    let i;
    for (i = -180; i <= 180; i += 1) {
      if (i % 5 === 0) {
        const obj = {
          value: i,
          label: i,
        };
        if (value === i) {
          setSelectedIndex(optionList.length);
        }
        optionList.push(obj);
      }
    }
    setOptions(optionList);
  };

  const FindSelectedIndex = (obj) => options.findIndex((x) => x.value === obj.value);
  useEffect(async () => {
    const zn = rowData.displayId.toString().split("-");
    if (zn.length === 2) {
      setZone(zn[1]);
    }
    if (zn.length === 3) {
      setZone(`${zn[1]} ${zn[2]}`);
    }
    const obj = {
      value: rowData.rotation === "0" ? 0 : rowData.rotation,
      label: rowData.rotation === "0" ? 0 : rowData.rotation,
    };
    calculateOptions(obj.value);
    setRotation(obj);
  }, []);

  const onOptChange = (e) => {
    setRotation(e);
    setSelectedIndex(FindSelectedIndex(e));
  };

  const chooseRotation = () => {
    closeRotationModal(rotation.value);
  };

  const CloseRtnModel = () => {
    closeRotationModal(false);
  };

  return (
    <>
      <div id="rotationModal" className="m-auto mg-top-3">
        <div className="modal-dialog m-0">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={CloseRtnModel}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <h2 className="modal-title text-white text-uppercase">
              Choose light
              <br />
              Source Rotation
            </h2>
            <div className="current-selection d-flex">
              <p className="text-capitalize" style={{ minWidth: "24%" }}>
                Current Zone:
                {` ${zone}`}
              </p>
              <p className="mr-0">
                Light Source:
                {` ${rowData.sDisplayName}`}
              </p>
            </div>
            <div className="rotation-option">
              <div className="form-group">
                <label>Rotation</label>
                <Select
                  value={options[selectedIndex]}
                  onChange={onOptChange}
                  options={options}
                  className="select-ldd"
                  autoFocus={false}
                  isSearchable
                  styles={Helper.getDropDownStyle(false)}
                />
              </div>
            </div>

            <div className="vehicle-side d-flex align-items-center text-center mx-auto">
              <p className="mr-0 ml-4">
                Front
                <br />
                of
                <br />
                vehicle
              </p>
              <div className="headlight-rotation">
                <img src={carImg} className="img-fluid mx-3" alt="" />
                {rowData.zone === "B" &&
                (rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={barB}
                    className="img-fluid barB"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "C" &&
                (rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={barB}
                    className="img-fluid barB"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 90}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "D" &&
                (rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={barB}
                    className="img-fluid barB"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 180}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "A" &&
                (rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={barB}
                    className="img-fluid barB"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 270}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "B" &&
                !(rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={headB}
                    className="img-fluid headB"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "C" &&
                !(rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={headB}
                    className="img-fluid headC"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 90}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "D" &&
                !(rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={headB}
                    className="img-fluid headD"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 180}deg)` }}
                  />
                ) : (
                  ""
                )}
                {rowData.zone === "A" &&
                !(rowData.lightType === "Lightbar" || rowData.lightType === "ImportedLightbar") ? (
                  <img
                    src={headB}
                    className="img-fluid headA"
                    alt=""
                    style={{ transform: `rotate(${options[selectedIndex]?.value + 270}deg)` }}
                  />
                ) : (
                  ""
                )}
              </div>
              <p>
                Rear
                <br />
                of
                <br />
                vehicle
              </p>
            </div>
            <div className="desc align-items-center text-center mx-auto mb-7" style={{ width: "60%" }}>
              {rowData.zone === "A"
                ? "Negative rotation turns light towards Zone D Positive rotation turns light towards Zone B"
                : ""}
              {rowData.zone === "B"
                ? "Negative rotation turns light towards Zone A Positive rotation turns light towards Zone C"
                : ""}
              {rowData.zone === "C"
                ? "Negative rotation turns light towards Zone B Positive rotation turns light towards Zone D"
                : ""}
              {rowData.zone === "D"
                ? "Negative rotation turns light towards Zone C Positive rotation turns light towards Zone A"
                : ""}
            </div>

            <div className="d-flex justify-content-center">
              <button type="submit" className="btn btn-red" onClick={chooseRotation}>
                Confirm Rotation
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RotationModel;
