// Pdf display modal component
import React, { useState } from "react";
import Pdf from "../../assets/document/AOG_EULA_v2.pdf";

const PdfPopupModal = ({ onClickClosePdf }) => {
  const [isLoading, setIsLoading] = useState(false);

  const timedLoader = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <>
      <div id="pdfPopupModal" className="m-auto">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-icon text-right">
              <button type="button" className="close" data-dismiss="modal" onClick={onClickClosePdf}>
                <span aria-hidden="true">
                  <i className="fa fa-times text-red" />
                </span>
              </button>
            </div>
            <div className="modal-title text-white text-uppercase">END-USER LICENSE AGREEMENT FOR WHELEN SOFTWARE</div>
            <div className="desc mb-5 mt-5" style={{ maxHeight: "640px", overflow: "scroll", paddingRight: "10px" }}>
              <p>
                IMPORTANT--READ CAREFULLY: This software application, the Apparatus Outfitting Guide (AOG) calculator
                and report, and associated media, printed materials, and “online” or electronic documentation, and any
                modification thereof described herein (collectively, the “Software Application”) is licensed, not sold,
                and is made available to users only under the conditions set forth in this End-User License Agreement
                (“Agreement”). This Agreement is a legal contract between you (either an individual or a single business
                entity) and Whelen Engineering Company, Inc (“Whelen”), a Connecticut corporation having a place of
                business at 51 Winthrop Road, Chester, CT 06412.
              </p>
              <p>
                BY CLICKING THE [ENTER] OR [ACCEPTED] ICON BELOW, OR BY INSTALLING, COPYING, ACCESSING OR OTHERWISE
                USING THE SOFTWARE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, INCLUDING THE
                WARRANTY DISCLAIMERS, LIMITATIONS OF LIABILITY AND TERMINATION PROVISIONS BELOW. IF YOU DO NOT AGREE TO
                THE TERMS OF THIS AGREEMENT, YOU DO NOT THE RIGHT TO ACCESS OR USE THE SOFTWARE APPLICATION - DO NOT
                INSTALL OR USE THE SOFTWARE APPLICATION, AND YOU MUST EXIT NOW.
              </p>
              <p>
                Whelen reserves the right to make changes to this End-User License Agreement at any time. You agree that
                by your continued use or access to the Software Application you are bound by the changes.
              </p>
              <p>The Software Application is subject to the following limitations:</p>
              <ul>
                <li>
                  The warning light package must be entirely laid out per the requirements of the standards selected by
                  the user in the Software Application (the “User”) and must be entirely designed per the requirements
                  of those standards.
                </li>
                <li> Lightheads must be set in a compliant flash pattern per the standards selected by the User. </li>
                <li> The AOG calculator data is based on 75 flashes per minute and on 40% flash duty cycle. </li>
                <li>
                  OEM’s must ensure any Lightheads are configured in their default power mode and not set to a “low
                  power,” “low intensity,” or similar mode. The AOG calculator assumes the default power mode of each
                  Lighthead when determining whether a given configuration passes or fails the applicable standard. The
                  use of a power mode other than the default by an OEM voids the results of the AOG calculator and
                  constitutes a material breach of this Agreement. Whelen will not be responsible for, and disclaims any
                  and all liability arising from or related to, the use of the Software Application in connection with
                  Lightheads configured in any power mode other than their default mode.
                </li>
                <li>Lightheads must be mounted in the defined positions as listed within the applicable standard.</li>
                <li>
                  Lightheads in each zone must be mounted in the defined positions as listed within the applicable User
                  selected standards.
                </li>
                <li>
                  Note: The sufficient number of optical warning devices must be used regardless of the AOG calculation.
                  The AOG calculation does not consider optical warning device count(s) as required by the standards.
                  The calculator may define one warning light as passing, but the standard may require more than one
                  warning light to be used.
                </li>
                <li>The User must define all zone “blockage” within the calculator.</li>
                <li>
                  The User must define within the calculator in the Software Application, all zone “obstructions” of
                  each warning light.
                </li>
                <li>
                  Lighting colors must be installed within the correct zones and only calculated as defined by the
                  standards selected by the User.
                </li>
                <li>
                  Lighting colors that are installed in zones that are not permissible will not be part of the National
                  Fire Protection Association optical power calculations in the AOG calculator in the Software
                  Application. OEM’s are responsible to disable those lights.
                </li>
              </ul>
              <p>LICENSE TERMS</p>
              <p>
                <b>1. License Grant.</b>
              </p>
              <p>
                (a)
                <b> General License. </b>
                Whelen hereby grants you the personal, nontransferable, and nonexclusive right and license to (i)
                install the Software Application on a single computer associated with Whelen equipment and to use the
                Software Application only with Whelen equipment or (ii) access the Software Application if it is made
                available to you by Whelen as a SAAS implementation.
              </p>
              <p>
                (b)
                <b> Hard Media License. </b>
                If this Software Application is delivered in hard media form (e.g., CDROM) you may make one copy of the
                Software Application solely for non-productive archival backup purposes in accordance with your standard
                procedures. All copies of the Software Application prepared by your organization must include the
                copyright notice(s) and proprietary legend(s) of Whelen as they appear in the Software Application and
                on the media containing the Software Application supplied hereunder.
              </p>
              <p>
                <b>2. Restrictions</b>
              </p>
              <p>
                (a) You are not permitted to modify, sell, or distribute the Software Application or to create
                derivative works from the Software Application, other than generating configuration output files as a
                result of the proper use of the Software Application as described in the accompanying documentation.
              </p>
              <p>
                (b) Any use, access, copying, or distribution of the Software Application or configuration files not
                authorized by this Agreement shall automatically terminate your right and license hereunder.
              </p>
              <p>
                {`(c) This license is limited to the access or use of the Software Application with the Whelen equipment
                for which the Software Application is certified in Whelen's published specifications.`}
              </p>
              <p>
                (d) Installation, access or use of the Software Application on processors accessible through
                communications networks through terminals and devices not on premises owned or controlled by you is
                prohibited.
              </p>
              <p>
                (e) You agree not to “unlock,” decompile, or reverse-assemble the binary or object code portions or
                versions of the Software Application.
              </p>
              <p>
                (f) You agree to use reasonable efforts to prevent access or use of the Software Application beyond the
                scope of this Agreement.
              </p>
              <p>
                <b>3. Ownership and Licensing of Modifications.</b>
              </p>
              <p>
                3.1
                <b> Requested Modifications. </b>
                Subject to Whelen’s agreement, You may request modifications or changes to the Software Application
                resulting in a derivative work being produced for you from the Software Application. Whelen has no
                obligation to provide requested modifications or derivative works and retains the sole and complete
                discretion as to whether to do so. You may be charged a fee for requested changes based on the work
                necessary to provide you with the changes or modifications. Payment of such fee does not create an
                ownership interest in the resulting modified Software Application or derivative work.
              </p>
              <p>
                3.2
                <b> Ownership of Requested Modifications. </b>
                Whelen shall have sole and exclusive ownership of all right, title, and interest in and to the modified
                Software Application or derivative work contemplated in Section 3.1; all media and documentation
                relating to the modifications, their design and development, including, without limitation, any images,
                photographs, animations, video, audio, music, text, and “applets” incorporated into the Software
                Application, all reports and copies thereof; and all intellectual property rights associated therewith
                (including, without limitation, rights to copyrights, trade secrets, or know-how). Unless otherwise
                expressly agreed in writing, any modified Software Application or derivative work will be licensed to
                you as part of the Software Application according to the terms set forth herein. For purposes of this
                Agreement the modified Software Application or derivative work is contained within the definition for
                the defined term Software Application.
              </p>
              <p>
                <b> 4. CONFIDENTIALITY. </b>
                {`The Software Application, including the object code, source code, and output files (including
                configurations and metrics) constitute and contain substantial trade secrets of Whelen. You agree to
                maintain in confidence the Software Application, all documentation relating to the design, development,
                and testing of the Software Application (collectively, “Whelen Confidential Information”), and you agree
                not to transfer or disclose Whelen Confidential Information to any third party without written consent
                from Whelen. You further agree not to disclose such Whelen Confidential Information to anyone other than
                employees, customers and contractors who have a need to know and who are bound to protect such
                information as confidential. You agree to use a reasonable degree of care to prevent the unauthorized
                disclosure of the Whelen Confidential Information and to employ at least the same physical and other
                security measures as you use to protect your own confidential information in order to guard against any
                use or disclosure of such information that is not necessary for you to disclose to access and use the
                Software Application under this Agreement. Upon completion of the development of modified Software
                Application or derivative work, or discontinuance of design and development for any reason, or
                discontinued access and use of the Software Application by you, you agree to return or, if specifically
                requested by Whelen, destroy all media and documentation containing such information, and you further
                agree to certify compliance with such obligation in writing if requested by Whelen. These obligations
                shall not apply to any information which is or becomes generally available to the public without breach
                of this Agreement by you, which is independently developed by you without reliance on Whelen's
                information, which is obtained by you from a third party, or disclosure required by law or which is
                approved for release by Whelen without restriction.`}
              </p>
              <p>
                <b>5. PROPRIETARY RIGHTS</b>
              </p>
              <p>
                5.1
                <b> Title to Software Application, Data Metrics and Configuration Files. </b>
                Title to the Software Application and any modified Software Application, including all copies thereof
                prepared by your organization and all data metrics and configuration output files generated therefrom,
                shall be in and remain with Whelen. In the event and to the extent you acquire any right, title and/or
                interest in the Software Application and/or any modified Software Application, you agree to and do
                hereby assign such right, title and/or interest to Whelen.
              </p>
              <p>
                5.2
                <b> Copyright. </b>
                All title and copyrights in and to the Software Application, the accompanying media and printed
                materials, and any copies of the Software Application, data metrics and configuration files are owned by
                Whelen.
              </p>
              <p>
                5.3
                <b> Submissions. </b>
                Should you decide to transmit to Whelen by any means or by any media any materials or other information
                (including, without limitation, ideas, concepts or techniques for new or improved services and
                products), whether as information, feedback, data, questions, comments, suggestions or the like, you
                agree such submissions are unrestricted and shall be deemed non-confidential and you grant Whelen and
                its assigns a nonexclusive, royalty-free, worldwide, perpetual, irrevocable license, with the right to
                sublicense, to use, copy, transmit, distribute, create derivative works of, display and perform the
                same.
              </p>
              <p>
                5.4
                <b> Metrics. </b>
                {`During the installation and use of the Software Application, the Software Application will capture
                certain data metrics from the Software Application's in-memory stream and output files; such data
                metrics will be automatically reported to Whelen by the Software Application and are the property of
                Whelen. You irrevocably agree to Whelen's collection and use of such data metrics for business purposes
                and you hereby assign to Whelen all right title and interest pertaining to such data metrics.`}
              </p>
              <p>
                6.
                <b> DISCLAIMER OF WARRANTY. </b>
                THIS SOFTWARE APPLICATION IS PROVIDED “AS IS” WITH NO WARRANTY OF ANY KIND. WHELEN DOES NOT WARRANT THE
                PERFORMANCE OF THE SOFTWARE APPLICATION OR RESULTS YOU MAY OBTAIN BY USING THE SOFTWARE APPLICATION.
                WHELEN MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AS TO TITLE OR INFRINGEMENT OF THIRD-PARTY RIGHTS,
                MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE.
              </p>
              <p>
                ANY LIABILITY OF WHELEN FOR A DEFECTIVE COPY OF THE SOFTWARE APPLICATION WILL BE LIMITED EXCLUSIVELY TO
                REPLACEMENT OF YOUR COPY OF THE SOFTWARE APPLICATION WITH ANOTHER COPY. IN NO EVENT WILL WHELEN BE
                LIABLE TO YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, INCLUDING ANY LOST
                PROFITS OR LOST SAVINGS, EVEN IF A WHELEN REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES, OR FOR ANY CLAIM BY ANY THIRD PARTY.
              </p>
              <p>
                YOU AGREE TO INDEMNIFY AND HOLD HARMLESS WHELEN, ITS SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES AND
                AGENTS FROM AND AGAINST ANY ACTION, CAUSE, CLAIM, DAMAGE, DEBT, DEMAND OR LIABILITY ASSERTED BY ANY
                PERSON OR ENTITY ARISING FROM OR RELATING TO (A) THIS AGREEMENT; (B) YOUR ACESS AND/OR USE OF THE
                SOFTWARE APPLICATION, AND (C) ANY BREACH BY YOU OF THE TERMS OF THIS AGREEMENT.
              </p>
              <p>
                7.
                <b> Term. </b>
                The terms and conditions of this Agreement shall remain in effect for so long as you use, access,
                install and/or execute the Software Application on your system or otherwise make any access or use of
                the Software Application, provided that Whelen reserves the right to terminate this Agreement at any
                time for any reason.
              </p>
              <p>
                8.
                <b> No Assignment. </b>
                This Agreement shall inure to the benefit of, and be binding on, your organization and Whelen, and their
                respective successors and assigns. You may not assign this Agreement except to a successor to all or
                substantially all of the business and properties of your organization dealing with the Software
                Application. References to “your organization” or “you” herein, for purposes of establishing the
                permitted use of the Software Application, shall include the operations of any direct or indirect parent
                or subsidiary company or of any direct or indirect subsidiary company of any such parent company.
              </p>
              <p>
                9.
                <b> Governing Law. </b>
                This Agreement and the rights and obligations of the parties with respect to the Software Application
                shall be governed by the laws of the State of Connecticut, as they apply to a contract negotiated,
                executed, and performed in that state, without regard to conflict of law principles. You agree to the
                jurisdiction of the courts of both the state and/or federal courts of Connecticut as the exclusive forum
                for resolution of any dispute arising from or relating to this Agreement or to the formation of this
                Agreement.
              </p>
              <p>
                10.
                <b> Complete Agreement; Modifications. </b>
                This Agreement constitutes the entire agreement between the parties and supersedes all prior or
                contemporaneous communications, agreements and understandings, written or oral, with respect to the
                subject matter hereof. This Agreement shall not be amended or modified except in a writing signed by
                authorized representatives of each party. Whelen reserves the right at any time to modify this Agreement
                and/or the Software Application, and to add new or additional terms or conditions on your use of the
                Software Application. Such modifications and additional terms and conditions may be communicated to you
                and, in any event,will be effective immediately upon your access or use of the Software Application
                thereafter.. In any event, Whelen reserves the right to immediately terminate the Agreement in its sole
                discretion if you refuse to accept such changes.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <a href={Pdf} download="AOG_EULA_v2.pdf">
                <button type="submit" className="btn btn-red" onClick={timedLoader} disabled={isLoading}>
                  {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                  Download PDF
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfPopupModal;
