// component for display zone sidebar
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Droppable } from "react-beautiful-dnd";

import WhelenWImg from "../../../assets/images/Whelen-Bowtie-White.svg";
import HeaderComponent from "../../Common/ZoneHeader";
import MySources from "./MySources";
import CommonlySource from "./CommonLights";

import LightModal from "./LightModal";
import ZoneResultPage from "./ZoneResult";
import ZoneResultPageMoz from "./zoneResultMoz";

const { detect } = require("detect-browser");

const browser = detect();

const ZoneSidebarPage = ({
  history,
  lightsData,
  mySources,
  commonlyUsed,
  setMySources,
  deleteLightsFromPreference,
  deleteFromMysources,
  isResultAvailable,
  closePerformCalculationResult,
  performCalculationData,
  printWarning,
}) => {
  const [isOpenModal, setAddLightModal] = useState(false);
  const [filteredLightData, setFilteredLightData] = useState([]);

  const openAddLightSourceModal = () => {
    setAddLightModal(true);
  };

  const closeAddLightSourceModal = () => {
    setAddLightModal(false);
  };

  const getFilteredLights = () => {
    const filteredLights = [];
    JSON.parse(JSON.stringify(lightsData)).forEach((element) => {
      if (element.lightType === "Lightbar" && element.isActive === true) {
        filteredLights.push(element);
      } else if (element.lightType !== "Lightbar" && element.isActive === true && element.excluded === false) {
        filteredLights.push(element);
      } else if (element.lightType === "Specialty" && element.isActive === true) {
        filteredLights.push(element);
      }
    });
    setFilteredLightData(filteredLights);
  };

  useEffect(async () => {
    getFilteredLights();
  }, [lightsData]);
  return (
    <div className="sidebar">
      <HeaderComponent history={history} />
      {isResultAvailable ? (
        browser.name === "firefox" ? (
          <ZoneResultPageMoz
            performCalculationData={performCalculationData}
            closePerformCalculationResult={closePerformCalculationResult}
            printWarning={printWarning}
          />
        ) : (
          <ZoneResultPage
            performCalculationData={performCalculationData}
            closePerformCalculationResult={closePerformCalculationResult}
            printWarning={printWarning}
          />
        )
      ) : (
        <div className="zone-sidebar">
          <div className="sidebar-header">
            <a href="#" onClick={openAddLightSourceModal}>
              <h2 className="text-white text-uppercase">
                <i className="fa fa-plus" style={{ color: "#E62629" }} />
                Add a Light Source
              </h2>
            </a>
          </div>
          <div className="sidebar-content">
            <div className="source-list">
              <h3 className="text-white text-uppercase my-source-title">My Light Sources</h3>
              <div className="my-sources-light-source d-flex justify-content-between flex-column">
                <Droppable droppableId="column-1" type="TASK">
                  {(provided, snapshot) => (
                    <div
                      style={{
                        transition: "background-color 0.2s ease",
                        height: "100%",
                      }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      <div className="my-sources-light-source-list">
                        {mySources &&
                          mySources.map((source, index) => (
                            <MySources
                              key={source.id}
                              deleteFromMysources={deleteFromMysources}
                              source={source}
                              index={index}
                            />
                          ))}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
            <div className="used-light-source d-flex justify-content-between flex-column">
              <div className="used-light-source-title d-flex justify-content-between align-items-center">
                <h3 className="text-white text-uppercase mb-0">My Saved Light Sources</h3>
              </div>
              <Droppable droppableId="commonlyUsedLights" type="TASK">
                {(provided, snapshot) => (
                  <div
                    style={{
                      transition: "background-color 0.2s ease",
                      height: "100%",
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <div className="used-light-source-list">
                      {commonlyUsed &&
                        commonlyUsed.map((source, index) => (
                          <CommonlySource
                            key={source.id}
                            source={source}
                            index={index}
                            deleteLightsFromPreference={deleteLightsFromPreference}
                          />
                        ))}
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          <div className="text-center">
            <img alt="" src={WhelenWImg} className="img-fluid" style={{ height: "33px", marginTop: "-5%" }} />
          </div>
        </div>
      )}
      <Modal
        className="lights-modal"
        centered
        backdrop="static"
        tabIndex="-1"
        show={isOpenModal}
        onHide={closeAddLightSourceModal}
      >
        <LightModal
          setMySources={setMySources}
          closeAddLightSourceModal={closeAddLightSourceModal}
          lightsData={filteredLightData}
        />
      </Modal>
    </div>
  );
};

export default ZoneSidebarPage;
