const pdfScriptFooterPagination = (printWarning) => `window.onload = function() {
    let resultData = 0;
    if (document.querySelector(".results-data")) {
        resultData = document.querySelector(".results-data").clientHeight;
    }
    let contentHeight = document.querySelector(".header-details").clientHeight + resultData + 80 + 60 + 25;
    let totalNumberOfPage = 1;
    let PAGE_HEIGHT = 1470;
    let LIGHT_PAGE_BREAK_HEIGHT = 1470;
    let LIGHTBAR_PAGE_BREAK_HEIGHT = 1492;
    let WARNING_PAGE_HEIGHT = 1470;
    let FOOTER_HEIGHT = 1510;
    let numberOfPage = 1;
    if (window.devicePixelRatio === 1 || window.devicePixelRatio === 1.25 || window.devicePixelRatio === 1.5) {
       LIGHTBAR_PAGE_BREAK_HEIGHT = 1482;
       LIGHT_PAGE_BREAK_HEIGHT = 1460;
       WARNING_PAGE_HEIGHT = 1470;
    }
    const printElement = document.getElementById("light-lightbar")
    if (printElement) {
        printElement.classList.add("temp-class-for-height");
        const height = printElement.clientHeight;
        numberOfPage = Math.ceil(height / PAGE_HEIGHT);
    }
    let numberOfWarningPage = 0;
    if ("${printWarning}" == 'true') {
        const warningText = 40;
        let warningContent = document.querySelector(".warningHeaderContent").clientHeight + warningText;
        let warningPageCount = 1;
        document.querySelectorAll(".warningRows .warning-row").forEach((row, index) => {
            warningContent += row.clientHeight;
            if (warningContent > (1460 * warningPageCount)) {
                console.log(warningContent, (1460 * warningPageCount), "page space");
                row.setAttribute("style", "page-break-before: always; break-before: always");
                warningPageCount++;
            }
        })
        numberOfWarningPage = warningPageCount;
    }
    totalNumberOfPage = numberOfPage + numberOfWarningPage;
    let rowHeight = contentHeight;
    let pageBreak = 1;
    document.querySelectorAll(".zone-table tr").forEach((row, index) => {
        rowHeight += row.clientHeight;
        if (rowHeight > LIGHT_PAGE_BREAK_HEIGHT * pageBreak) {
            const paginationSpace = document.querySelector(".zone-table").insertRow(index);
            paginationSpace.innerHTML = "<td colspan='9' class='light-pagination-" + pageBreak + "'>Page " + pageBreak + " of&nbsp;<span class='total-page'> " + totalNumberOfPage + "</span></td>";
            const tableHeading = document.querySelector(".zone-table").insertRow(index + 1);
            tableHeading.classList.add("page-break-header");
            const headingEntry = document.querySelector(".zone-table tr").innerHTML;
            tableHeading.innerHTML = headingEntry;
            pageBreak++;
        }
        if (document.querySelector(".light-pagination")) {
            document.querySelector(".light-pagination").innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'> " + totalNumberOfPage + "</span>";
        }
    });
    let existingContentHeight = contentHeight + document.querySelector(".zone-table-data").clientHeight;
    let isSpaceAvailableForLightbars = true;
    if (existingContentHeight > LIGHTBAR_PAGE_BREAK_HEIGHT * pageBreak) {
        isSpaceAvailableForLightbars = false;
    }
    console.log(existingContentHeight, "existingContentHeight");
    if (document.querySelectorAll("[class*=lightbar-counter-").length) {
        document.querySelectorAll("[class*=lightbar-counter-").forEach((lightbar, lightbarIndex) => {
        if (pageBreak) {
            existingContentHeight += lightbar.clientHeight + 22;
            if (existingContentHeight > LIGHTBAR_PAGE_BREAK_HEIGHT * pageBreak) {
                if (document.querySelector(".lightbar-counter-" + (lightbarIndex - 1))) {
                    document.querySelector(".lightbar-counter-" + (lightbarIndex - 1)).setAttribute("style", "page-break-after: always; break-after: always");
                    document.querySelector(".lightbar-page-" + (lightbarIndex - 1)).innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'>"  + totalNumberOfPage + "</span>";
                } else {
                    if (isSpaceAvailableForLightbars || lightbarIndex > 0) {
                        document.querySelector(".lightbar-counter-" + (lightbarIndex)).setAttribute("style", "page-break-before: always; break-before: always");
                    }
                    document.querySelector(".lightbar-page-" + (lightbarIndex)).innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'> "  + totalNumberOfPage + "</span>";
                }
                pageBreak++;
            }
            if (document.querySelectorAll("[class*=lightbar-counter-").length === (lightbarIndex + 1)) {
                if(document.querySelector(".light-pagination")) {
                    document.querySelector(".light-pagination").innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'> " + totalNumberOfPage + "</span>";
                } else {
                    document.querySelector(".lightbar-page").innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'> " + totalNumberOfPage + "</span>";
                }
            }
        }                   
        });
    }
    let increasePage = false;
    if (pageBreak > numberOfPage) {
        numberOfPage = pageBreak;
        const newTotalPageDiff = 1;
        const existingTotalPage = document.querySelector(".total-page").innerHTML;
        const newPageTotal = parseInt(existingTotalPage) + parseInt(newTotalPageDiff);
        document.querySelectorAll(".total-page").forEach((sp) => sp.innerText = newPageTotal);
        increasePage = true;
    }
    document.querySelectorAll("[class*=light-pagination]").forEach((lightPaginate) => {
        const paginate = lightPaginate.innerHTML;
        const className = lightPaginate.className;
        const span = document.createElement("SPAN");
        span.className = className + '-page';
        span.innerHTML = paginate;
        document.querySelector(".custom-pagination").append(span);
        lightPaginate.remove(); 
    });
    document.querySelectorAll("[class*=lightbar-page]").forEach((lightpagination) => {
        if (lightpagination.innerHTML !== "") {
            const paginate = lightpagination.cloneNode(true);
            document.querySelector(".custom-pagination").append(paginate); 
            lightpagination.remove(); 
        }
    });
    for (i = 0; i < numberOfWarningPage; i++) {
        pageBreak = pageBreak + 1;
        const span = document.createElement("SPAN");
        span.className = 'warning-pagination-' + i;
        let totalPage = parseInt(totalNumberOfPage);
        if (increasePage) {
            totalPage += 1;
        }
        span.innerHTML = "Page " + pageBreak + " of&nbsp;<span class='total-page'>" + totalPage + "</span>";
        document.querySelector(".custom-pagination").append(span);
    }
    let lightPosition = 0;
    let warningPosition = 0;
    let lastPrintPage = 0;
    let lastWarningPrintPage = 0;
    document.querySelector(".custom-pagination").childNodes.forEach((node, i) => {
        let extraTop = 10;
        let extraLeft = '';
        if (i > 0) {
         extraTop = (i * 25) + (i * 15); 
         extraLeft = "left: " + (i * 5 + (i + 1)) + "%!important";
        }
        extraTop -= i * 0.4;
        const position = parseInt(FOOTER_HEIGHT * (i + 1)) + parseInt(extraTop);
        if (numberOfPage - 1 == i) {
            lastPrintPage = position;
        }
        node.setAttribute(
        "style",
        "height: 0; font-size: 12px; font-weight: bold; position: relative; float: right; overflow: visible; top:" + position + "px;"
        );
        if (node.className.indexOf("light-pagination") !== -1 || node.className.indexOf("lightbar-page") !== -1) {
          lightPosition = extraTop;
        }
        if (node.className.indexOf("warning-pagination") !==- 1 ) {
            warningPosition = extraTop;
        }
        lastWarningPrintPage = position;
        if (document.querySelector(".custom-pagination").childNodes.length - 1 === i && totalNumberOfPage > 10) {
            lastWarningPrintPage -= 0.7 * totalNumberOfPage; 
        }
        node.setAttribute("top", position);
    })

    function getChildPosition(childElement) {
        var parentElement = childElement.parentNode;
        var childRect = childElement.getBoundingClientRect();
        var parentRect = parentElement.getBoundingClientRect();
        var position = {
          left: childRect.left - parentRect.left,
          top: childRect.top - parentRect.top,
          parentheight: parentRect.height
        };
        return position;
    }
    const cloneNode = document.querySelector(".footer-1").cloneNode(true);
    document.querySelector(".custom-footer-1").appendChild(cloneNode);
    const footer1 = lastPrintPage;
    document.querySelector(".custom-footer-1").setAttribute(
    "style",
    "top: " + footer1 + "px!important; position: relative; overflow: visible;"
    );
    if (document.querySelector(".footer-2")) {
        const cloneNode = document.querySelector(".footer-2").cloneNode(true);
        document.querySelector(".custom-footer-2").appendChild(cloneNode);
        const footer2 = parseInt(FOOTER_HEIGHT * totalNumberOfPage) + warningPosition; 
        document.querySelector(".custom-footer-2").setAttribute(
            "style",
            "top: " + lastWarningPrintPage + "px!important; position: relative; overflow: visible;"
        );
    }
    if (numberOfPage === 1 && document.querySelector('.custom-page-break') && ${printWarning} !== true) {
        document.querySelector('.custom-page-break').style.breakAfter = 'avoid'; // firefox
        document.querySelector('.custom-page-break').style.pageBreakAfter = 'avoid';
    }
    setTimeout(() => window.print(), 1000) }`;

export default pdfScriptFooterPagination;
