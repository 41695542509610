// Request Access component
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// Media Components
import Helper from "../../helper/helper";
import configData from "../../config/config";

// Services
import AuthService from "../../services/AuthService";

const RequestAccess = ({ closeRAModal }) => {
  const { SUCCESS_MESSAGE } = configData;
  const [isLoading, setIsLoading] = useState(false);
  const nameWarningMessage = "Please enter valid characters only (English alphabets, space and hyphen)";

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter valid Email Address (Format abc@xyz.com)")
      .required("Email Address is Required")
      .min(5, "Please enter valid Email Address (Format abc@xyz.com)")
      .max(128, "Please enter valid Email Address (Max 128 character allowed in format abc@xyz.com"),
    firstName: Yup.string()
      .required("First Name is required")
      .max(30, "Please enter valid First Name (Maximum 30 character allowed)")
      .matches(/[^- ](.*)$/, nameWarningMessage)
      .matches(/^[a-zA-Z -]+$/, nameWarningMessage),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(30, "Please enter valid Last Name (Maximum 30 character allowed)")
      .matches(/[^- ](.*)$/, nameWarningMessage)
      .matches(/^[a-zA-Z -]+$/, nameWarningMessage),
    company: Yup.string()
      .required("Company Name is required")
      .max(30, "Please enter valid Company Name (Maximum 30 character allowed)")
      .matches(
        /^[a-zA-Z-'._,0-9/ ]*$/,
        "Please enter valid Company Name (Allowed English character, numbers and ' , _ . - / )"
      )
      .matches(/^(?=.*[A-Za-z])[a-zA-Z-'._,0-9/ ]*$/, "Please enter valid Company Name (Minimum 1 letter required)"),
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  // function for make api call for login andhandle response
  const onSubmit = (data) => {
    setIsLoading(true);
    AuthService.requestAccess(data)
      .then(() => {
        Helper.displayNotification(SUCCESS_MESSAGE.RQUEST_ACCESSS, "success");
        setIsLoading(false);
        closeRAModal();
      })
      .catch((error) => {
        Helper.displayErrorMessage(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100 theme-bg">
        <section className="requestaccess-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="request-block">
                <div className="d-flex request-title">
                  <h2 className="text-white text-uppercase">Request Access</h2>
                  <div className="close-icon text-right">
                    <button type="button" className="close" data-dismiss="modal" onClick={closeRAModal}>
                      <span aria-hidden="true">
                        <i className="fa fa-times text-red" />
                      </span>
                    </button>
                  </div>
                </div>
                <form className="request-form" action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group row user-fg">
                    <label className="col-sm-3 col-form-label">First Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="firstName"
                        ref={register}
                        className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                        autoComplete="off"
                        maxLength={30}
                      />
                      {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
                    </div>
                  </div>
                  <div className="form-group row user-fg">
                    <label className="col-sm-3 col-form-label">Last Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="lastName"
                        ref={register}
                        className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                        autoComplete="off"
                        maxLength={30}
                      />
                      {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
                    </div>
                  </div>
                  <div className="form-group row user-fg">
                    <label className="col-sm-3 col-form-label">Email Address</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="email"
                        ref={register}
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        autoComplete="off"
                        maxLength={128}
                        style={{ "text-transform": "lowercase" }}
                      />
                      {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                    </div>
                  </div>
                  <div className="form-group row user-fg">
                    <label className="col-sm-3 col-form-label">Company Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="company"
                        ref={register}
                        className={`form-control ${errors.company ? "is-invalid" : ""}`}
                        autoComplete="off"
                        maxLength={30}
                      />
                      {errors.company && <div className="invalid-feedback">{errors.company.message}</div>}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mb-10">
                    <button type="submit" disabled={isLoading} className="btn btn-red mr-3">
                      {isLoading ? <span className="spinner-border spinner-border-sm mr-1" /> : ""}
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default RequestAccess;
