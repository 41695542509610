// component for display my soources
import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import ReactTooltip from "react-tooltip";

import White from "../../../assets/images/white.png";
import Red from "../../../assets/images/red.png";
import Blue from "../../../assets/images/blue.png";
import Amber from "../../../assets/images/amber.png";

import BlueWhite from "../../../assets/images/bw.png";
import WhiteBlue from "../../../assets/images/wb.png";
import BlueAmber from "../../../assets/images/ba.png";
import BlueRed from "../../../assets/images/br.png";
import RedBlue from "../../../assets/images/rb.png";
import RedWhite from "../../../assets/images/rw.png";
import WhiteRed from "../../../assets/images/wr.png";
import RedAmber from "../../../assets/images/ra.png";
import AmberWhite from "../../../assets/images/aw.png";
import AmberBlue from "../../../assets/images/ab.png";
import WhiteAmber from "../../../assets/images/wa.png";
import AmberRed from "../../../assets/images/ar.png";

import BlueAmberWhite from "../../../assets/images/baw.png";
import RedAmberWhite from "../../../assets/images/raw.png";
import RedBlueWhite from "../../../assets/images/rbw.png";
import RedBlueAmber from "../../../assets/images/rba.png";
import Green from "../../../assets/images/green.png";
import GreenWhite from "../../../assets/images/gw.png";
import WhiteGreen from "../../../assets/images/wg.png";
import GreenWhiteRed from "../../../assets/images/gwr.png";
import RedGreen from "../../../assets/images/rg.png";
import GreenRed from "../../../assets/images/gr.png";
import GreenBlue from "../../../assets/images/gb.png";
import GreenAmber from "../../../assets/images/ga.png";

import LightBar from "../../../assets/images/lightbar.png";

const MySources = ({ source, index, deleteFromMysources }) => {
  const images = {
    White,
    Red,
    BlueAmberWhite,
    BlueRed,
    RedBlue,
    RedAmberWhite,
    RedAmber,
    RedBlueWhite,
    RedBlueAmber,
    Blue,
    Amber,
    BlueWhite,
    WhiteBlue,
    BlueAmber,
    RedWhite,
    WhiteRed,
    AmberWhite,
    AmberBlue,
    WhiteAmber,
    AmberRed,
    Green,
    GreenWhite,
    WhiteGreen,
    GreenWhiteRed,
    RedGreen,
    GreenRed,
    GreenBlue,
    GreenAmber,
  };
  const [deleteIndex, setDeleteIndex] = useState("");
  useEffect(async () => {
    setDeleteIndex("");
  }, [source]);

  let colorName = "";
  if (source.color1) {
    colorName = source.color1;
    if (source.color2) {
      if (source.color2 !== source.color1) {
        colorName = `${colorName}${source.color2}`;
      }
      if (source.color3) {
        colorName = `${colorName}${source.color3}`;
      }
    }
  }
  const getImages = (image) => {
    let displayImage = White;
    if (images[image]) {
      displayImage = images[image];
    }
    return displayImage;
  };
  const deleteMySourceLight = (e, data) => {
    setDeleteIndex(index);
    deleteFromMysources(e, data);
  };
  return (
    <Draggable draggableId={`mySource-${source.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDragDisabled
        >
          <div
            data-all={JSON.stringify(source)}
            data-tip={source.tooltipData}
            data-for="commonLights"
            className="d-flex align-items-center zone-sidebar-list close-option"
            key={index}
          >
            <ReactTooltip id="commonLights" place="top" multiline type="light" effect="solid" />
            <img
              alt=""
              src={
                source.lightType === "ImportedLightbar" || source.lightType === "Lightbar"
                  ? LightBar
                  : getImages(colorName)
              }
            />
            <p>
              {source.showExlm && <i className="fa fa-exclamation pr-1" />}
              {source.showHash && <i className="fa fa-hashtag pr-1" />}
              {source.sDisplayName}
            </p>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              <a
                href="#"
                onClick={(e) => {
                  deleteMySourceLight(e, source);
                }}
              >
                {deleteIndex === index ? (
                  <div className="smallloader m-auto" />
                ) : (
                  <i className="fa fa-times close-option-icon text-danger" />
                )}
              </a>
            </span>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default MySources;
