// Set Arrow direction as per sorting order
const setArrow = (sort, column) => {
  let className = "sort-direction";

  if (sort.column === column) {
    className += sort.direction === "asc" ? " asc" : " desc";
  } else {
    className += " default";
  }
  return className;
};

// Compares two values
const compareValues = (a, b, column, direction) => {
  const nameA = a[column]?.toString().toUpperCase(); // ignore upper and lowercase
  const nameB = b[column]?.toString().toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB || (nameA === nameB && direction === "asc")) {
    return -1;
  }
  if (nameA > nameB || (nameA === nameB && direction === "desc")) {
    return 1;
  }
  if (direction === "asc") {
    return -1;
  }
  return 1;
};

const CommonUtiles = {
  setArrow,
  compareValues,
};

export default CommonUtiles;
// }
