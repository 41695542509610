// Light modal component

import React, { useState, useRef } from "react";
import Select, { components } from "react-select";
import { ToastContainer } from "react-toastify";
// import { async } from "rxjs";
import helper from "../../../helper/helper";
import config from "../../../config/config";
import ConfigService from "../../../services/ConfigService";

const LightModal = ({ closeAddLightSourceModal, lightsData, setMySources }) => {
  const [selectedObject, setSelectedObject] = useState("");
  const [selectedLightType, setSelectedLightType] = useState("");
  const [selectedSeries, setSelectedSeries] = useState("");
  const [selectedSubSeries, setSelectedSubSeries] = useState("");
  const [selectedLightColor, setSelectedLightColor] = useState("");
  const [selectedLensColor, setSelectedLensColor] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [searchTextData, setSelectedSearchText] = useState("");
  const [bxrFile, setbxrFile] = useState("");
  const [fileName, setFilename] = useState("");
  const [disable, setButtonDisable] = useState(false);
  const { ERROR_MESSAGE } = config;
  const fileInput = useRef("");

  // renders the search text box with lights data
  const searchText = lightsData.map((d) => ({
    sDisplayName: d.sDisplayName,
    id: d.id,
    lightType: d.lightType,
    value: d.sDisplayName,
    label: d.sDisplayName,
  }));
  searchText.sort((firstIndex, secondIndex) => firstIndex?.sDisplayName?.localeCompare(secondIndex.sDisplayName));

  const { lightType } = selectedLightType;
  const { series } = selectedSeries;
  const { subSeries } = selectedSubSeries;

  // renders the lights types
  let lightTypes = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
    },
  ];
  // renders the lights series
  let lightSeries = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
    },
  ];

  // renders the light sub-series
  let lightSubSeries = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
      subSeries: "",
    },
  ];

  // renders the lights colors
  let lightColors = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
      subSeries: "",
      colors: "",
      color1: "",
      color2: "",
      color3: "",
    },
  ];

  // renders the lens colors
  let lensColors = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
      subSeries: "",
      colors: "",
      color1: "",
      color2: "",
      color3: "",
      lensColor: "",
    },
  ];

  // renders the lights model
  let lightsModal = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
      subSeries: "",
      colors: "",
      color1: "",
      color2: "",
      color3: "",
      lensColor: "",
      modelNumber: "",
    },
  ];

  // renders the lights size
  let lightSize = [
    {
      lightType: "",
      label: "Please Select",
      value: "",
      series: "",
      subSeries: "",
      colors: "",
      color1: "",
      lensColor: "",
      modelNumber: "",
      size: "",
    },
  ];
  lightsData.map((e) => {
    let lights = "";
    if (e.color1) {
      lights = e.color1;
      if (e.color2) {
        lights = `${lights}/${e.color2}`;
        if (e.color3) {
          lights = `${lights}/${e.color3}`;
        }
      }
    }
    lightTypes.push({
      lightType: e.lightType,
      label: e.lightType,
      value: e.lightType,
    });

    if (e.lightType === lightType) {
      lightSeries.push({
        series: e.series,
        lightType: e.lightType,
        label: e.series,
        value: e.series,
      });
    }

    if (e.lightType === lightType && e.series === series) {
      lightSubSeries.push({
        series: e.series,
        value: e.subSeries,
        label: e.subSeries,
        lightType: e.lightType,
        subSeries: e.subSeries,
      });
    }

    if (e.lightType === lightType && e.series === series && (e.subSeries !== "" ? e.subSeries === subSeries : true)) {
      lightColors.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        colors: lights,
        color2: e.color2,
        color1: e.color1,
        color3: e.color3,
        label: lights,
        value: lights,
      });
    }
    if (
      e.lightType === lightType &&
      e.series === series &&
      (e.subSeries ? e.subSeries === subSeries : true) &&
      (selectedLightColor.colors
        ? (selectedLightColor.colors.split("/")[0] ? e.color1 === selectedLightColor.colors.split("/")[0] : true) &&
          (selectedLightColor.colors.split("/")[1] ? e.color2 === selectedLightColor.colors.split("/")[1] : true) &&
          (selectedLightColor.colors.split("/")[2] ? e.color3 === selectedLightColor.colors.split("/")[2] : true)
        : true)
    ) {
      lensColors.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        colors: lights,
        color2: e.color2,
        color1: e.color1,
        color3: e.color3,
        lensColor: e.lensColor,
        label: e.lensColor,
        value: e.lensColor,
      });
    }

    if (
      (lightType === "Lightbar" || lightType === "Specialty") &&
      e.lightType === lightType &&
      e.series === series &&
      (e.subSeries !== "" ? e.subSeries === subSeries : true)
    ) {
      lightSize.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        label: e.size,
        value: e.size,
        modelNumber: e.modelNumber,
        size: e.size,
      });
    }

    if (
      (lightType === "Lightbar" || lightType === "Specialty") &&
      e.lightType === lightType &&
      e.series === series &&
      (e.subSeries !== "" ? e.subSeries === subSeries : true) &&
      e.size === selectedSize.size
    ) {
      lightsModal.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        label: e.modelNumber,
        value: e.modelNumber,
        modelNumber: e.modelNumber,
        size: e.size,
      });
    }

    if (
      lightType !== "Lightbar" &&
      lightType !== "Specialty" &&
      (lightType === "Lighthead" || lightType === "Beacon") &&
      e.lightType === lightType &&
      e.series === series &&
      (e.subSeries !== "" ? e.subSeries === subSeries : true) &&
      (selectedLightColor.colors
        ? (selectedLightColor.colors.split("/")[0] ? e.color1 === selectedLightColor.colors.split("/")[0] : true) &&
          (selectedLightColor.colors.split("/")[1] ? e.color2 === selectedLightColor.colors.split("/")[1] : true) &&
          (selectedLightColor.colors.split("/")[2] ? e.color3 === selectedLightColor.colors.split("/")[2] : true)
        : true) &&
      e.lensColor === selectedLensColor.lensColor
    ) {
      lightsModal.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        colors: lights,
        color2: e.color2,
        color1: e.color1,
        color3: e.color3,
        label: e.modelNumber,
        value: e.modelNumber,
        lensColor: e.lensColor,
        modelNumber: e.modelNumber,
      });
    }

    if (
      lightType !== "Lightbar" &&
      lightType !== "Specialty" &&
      lightType !== "Lighthead" &&
      lightType !== "Beacon" &&
      e.lightType === lightType &&
      e.series === series
    ) {
      lightsModal.push({
        series: e.series,
        lightType: e.lightType,
        subSeries: e.subSeries,
        colors: lights,
        color2: e.color2,
        color1: e.color1,
        color3: e.color3,
        label: e.modelNumber,
        value: e.modelNumber,
        lensColor: e.lensColor,
        modelNumber: e.modelNumber,
      });
    }
    return null;
  });

  // On BXR file select
  const onFileChange = (event) => {
    const reader = new FileReader();
    const fileData = event.target.files[0];
    if (fileData) {
      reader.readAsDataURL(fileData);
      const uploadFileName = fileData.name;
      const checkFile = uploadFileName.toLowerCase();
      const regex = new RegExp("(.*?).(bxr)$");
      if (!regex.test(checkFile)) {
        helper.displayNotification(ERROR_MESSAGE.INVALID_BXR_FILE, "error");
        return;
      }
    }
    reader.onload = function () {
      setFilename(fileData.name);
      const strXML = reader.result.split("base64,").pop();
      setbxrFile(strXML);
    };
    reader.onerror = function () {
      helper.displayNotification(ERROR_MESSAGE.INVALID_BXR_FILE, "error");
    };
  };

  // used for adding lights to mysource
  const addLightsToSource = async () => {
    let lightsToAdd = null;
    setButtonDisable(true);
    // add lightbar using bxr file
    if (lightType === "Lightbar" && bxrFile) {
      const request = { bxrFile, fileName };
      await ConfigService.uploadBXR(request)
        .then((result) => {
          lightsToAdd = result.data;
          lightsToAdd.lightType = "ImportedLightbar";
          lightsToAdd.importedFileName = fileName;
        })
        .catch((err) => {
          helper.displayErrorMessage(err);
          setButtonDisable(false);
          setbxrFile("");
          setFilename("");
          fileInput.current.value = "";
        });
    } else {
      lightsData.map((lights) => {
        if (
          lightType !== "Lightbar" &&
          lightType !== "Specialty" &&
          lights.lightType === lightType &&
          lights.series === series &&
          (lights.subSeries !== "" ? lights.subSeries === subSeries : true) &&
          (selectedLightColor.colors
            ? (selectedLightColor.colors.split("/")[0]
                ? lights.color1 === selectedLightColor.colors.split("/")[0]
                : true) &&
              (selectedLightColor.colors.split("/")[1]
                ? lights.color2 === selectedLightColor.colors.split("/")[1]
                : true) &&
              (selectedLightColor.colors.split("/")[2]
                ? lights.color3 === selectedLightColor.colors.split("/")[2]
                : true)
            : true) &&
          lights.lensColor === selectedLensColor.lensColor &&
          lights.modelNumber === selectedModel.modelNumber
        ) {
          lightsToAdd = lights;
          lightsToAdd.isAllLightFound = true;
        }

        if (
          (lightType === "Lightbar" || lightType === "Specialty") &&
          lights.lightType === lightType &&
          lights.series === series &&
          (lights.subSeries !== "" ? lights.subSeries === subSeries : true) &&
          lights.size === selectedSize.size &&
          lights.modelNumber === selectedModel.modelNumber
        ) {
          lightsToAdd = lights;
        }

        if (
          lightType !== "Lightbar" &&
          lightType !== "Specialty" &&
          lightType !== "Lighthead" &&
          lightType !== "Beacon" &&
          lights.lightType === lightType &&
          lights.series === series &&
          lights.modelNumber === selectedModel.modelNumber
        ) {
          lightsToAdd = lights;
          lightsToAdd.isAllLightFound = true;
        }

        return null;
      });
    }
    if (lightsToAdd) {
      /* eslint-disable array-callback-return */
      // Missing/Inactive lightheads data for tooltip
      let tooltipData = lightsToAdd.sDisplayName;
      const missingLightheads = [];
      const inactiveLightheads = [];
      const excludedLightheads = [];
      let showExlm = false;
      let showHash = false;
      if (
        lightsToAdd.lightType === "Lightbar" ||
        lightsToAdd.lightType === "Specialty" ||
        lightsToAdd.lightType === "ImportedLightbar"
      ) {
        if (lightsToAdd.missingLightheads.length > 0) {
          lightsToAdd.missingLightheads.map((model) => {
            missingLightheads.push(model.LightModelNumber);
          });
        }
        if (lightsToAdd.inactiveLightheads.length > 0) {
          lightsToAdd.inactiveLightheads.map((model) => {
            inactiveLightheads.push(model.LightModelNumber);
          });
        }
        if (lightsToAdd.excludedLightheads.length > 0) {
          lightsToAdd.excludedLightheads.map((model) => {
            excludedLightheads.push(model.LightModelNumber);
          });
        }
        if (missingLightheads.length > 0) {
          const missingModels = missingLightheads.join(", ");
          tooltipData += `<br />Missing Lighthead(s): ${missingModels}`;
          showExlm = true;
        }
        if (inactiveLightheads.length > 0) {
          const inactiveModels = inactiveLightheads.join(", ");
          tooltipData += `<br />Inactive Lighthead(s): ${inactiveModels}`;
          showExlm = true;
        }
        if (excludedLightheads.length > 0) {
          const excludedModels = excludedLightheads.join(", ");
          tooltipData += `<br />Excluded Lighthead(s): ${excludedModels}`;
          showHash = true;
        }
        if (lightsToAdd.lightType === "ImportedLightbar") {
          tooltipData += `<br />File Name: ${fileName}`;
        }
      } else if (lightsToAdd.isActive === false || lightsToAdd.excluded === true) {
        if (lightsToAdd.isActive === false && lightsToAdd.excluded === true) {
          tooltipData = `Excluded and Inactive Lighthead: ${lightsToAdd.sDisplayName}`;
          showHash = true;
          showExlm = true;
        } else if (lightsToAdd.isActive === false && lightsToAdd.excluded === false) {
          tooltipData = `Inactive Lighthead: ${lightsToAdd.sDisplayName}`;
          showExlm = true;
        } else if (lightsToAdd.isActive === true && lightsToAdd.excluded === true) {
          tooltipData = `Excluded Lighthead: ${lightsToAdd.sDisplayName}`;
          showHash = true;
        }
      }
      lightsToAdd.showExlm = showExlm;
      lightsToAdd.showHash = showHash;
      lightsToAdd.tooltipData = tooltipData;
      lightsToAdd.importedFileName = fileName;
      await setMySources(lightsToAdd);
      setButtonDisable(false);
      closeAddLightSourceModal();
    } else if (!bxrFile) {
      setButtonDisable(false);
      helper.displayNotification(ERROR_MESSAGE.SELECT_INPUT, "error");
    }
  };

  // filters the lights types and removes same type
  lightTypes = lightTypes.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.lightType === value.lightType) === index
  );
  lightTypes.sort((firstIndex, secondIndex) => firstIndex?.lightType?.localeCompare(secondIndex.lightType));

  // filters the lights series and removes same data
  lightSeries = lightSeries.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.series === value.series) === index
  );
  lightSeries.sort((firstIndex, secondIndex) => firstIndex?.series?.localeCompare(secondIndex.series));

  // filters the lights sub-series and removes same data
  lightSubSeries = lightSubSeries.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.subSeries === value.subSeries) === index
  );
  lightSubSeries.sort((firstIndex, secondIndex) => firstIndex?.subSeries?.localeCompare(secondIndex.subSeries));
  // filters the lights colors and removes same data
  lightColors = lightColors.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.colors === value.colors) === index
  );
  lightColors.sort((firstIndex, secondIndex) => firstIndex?.colors?.localeCompare(secondIndex.colors));

  // filters the lens colors and removes same data
  lensColors = lensColors.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.lensColor === value.lensColor) === index
  );
  lensColors.sort((firstIndex, secondIndex) => firstIndex?.lensColor?.localeCompare(secondIndex.lensColor));

  // filters the lights modal and removes same data
  lightsModal = lightsModal.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.modelNumber === value.modelNumber) === index
  );
  lightsModal.sort((firstIndex, secondIndex) => firstIndex?.modelNumber?.localeCompare(secondIndex.modelNumber));

  // filters the lights size and removes same data
  lightSize = lightSize.filter(
    (value, index, attribute) => attribute.findIndex((item) => item.size === value.size) === index
  );
  lightSize.sort((firstIndex, secondIndex) => firstIndex?.size?.localeCompare(secondIndex.size));

  // used for handle text box focus that clears the old data
  const handleOnFocusbox = () => {
    setSelectedObject("");
    setSelectedLightType("");
    setSelectedSeries("");
    setSelectedSubSeries("");
    setSelectedLightColor("");
    setSelectedLensColor("");
    setSelectedModel("");
    setSelectedSearchText("");
    setSelectedSize("");
  };

  // used for handle change event of the search text box
  const changeSearchText = (value) => {
    const findIndexData = lightsData.findIndex((lights) => lights.sDisplayName === value.sDisplayName);
    let lights = "";
    if (lightsData[findIndexData].color1) {
      lights = lightsData[findIndexData].color1;
      if (lightsData[findIndexData].color2) {
        lights = `${lights}/${lightsData[findIndexData].color2}`;
        if (lightsData[findIndexData].color3) {
          lights = `${lights}/${lightsData[findIndexData].color3}`;
        }
      }
    }

    // used  for set select values to the dropdown
    setSelectedSearchText(value);
    if (findIndexData !== -1) {
      setSelectedObject(lightsData[findIndexData]);
      setSelectedLightType({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].lightType,
        value: lightsData[findIndexData].lightType,
      });
      setSelectedSeries({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].series,
        value: lightsData[findIndexData].series,
        series: lightsData[findIndexData].series,
      });
      setSelectedSubSeries({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].subSeries,
        value: lightsData[findIndexData].subSeries,
        series: lightsData[findIndexData].series,
        subSeries: lightsData[findIndexData].subSeries,
      });
      setSelectedLightColor({
        lightType: lightsData[findIndexData].lightType,
        label: lights,
        value: lights,
        series: lightsData[findIndexData].series,
        subSeries: lightsData[findIndexData].subSeries,
        colors: lights,
        color1: lightsData[findIndexData].color1,
        color2: lightsData[findIndexData].color2,
        color3: lightsData[findIndexData].color3,
      });
      setSelectedLensColor({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].lensColor,
        value: lightsData[findIndexData].lensColor,
        series: lightsData[findIndexData].series,
        subSeries: lightsData[findIndexData].subSeries,
        colors: lights,
        color1: lightsData[findIndexData].color1,
        color2: lightsData[findIndexData].color2,
        color3: lightsData[findIndexData].color3,
        lensColor: lightsData[findIndexData].lensColor,
      });
      setSelectedModel({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].modelNumber,
        value: lightsData[findIndexData].modelNumber,
        series: lightsData[findIndexData].series,
        subSeries: lightsData[findIndexData].subSeries,
        colors: lights,
        color1: lightsData[findIndexData].color1,
        color2: lightsData[findIndexData].color2,
        color3: lightsData[findIndexData].color3,
        lensColor: lightsData[findIndexData].lensColor,
        modelNumber: lightsData[findIndexData].modelNumber,
      });
      setSelectedSize({
        lightType: lightsData[findIndexData].lightType,
        label: lightsData[findIndexData].size,
        value: lightsData[findIndexData].size,
        series: lightsData[findIndexData].series,
        subSeries: lightsData[findIndexData].subSeries,
        modelNumber: lightsData[findIndexData].modelNumber,
        size: lightsData[findIndexData].size,
      });
    }
  };

  // used for handle change of light type drop-down
  const changeSelectedLightType = (value) => {
    setSelectedObject("");
    setSelectedLightType(value);
    setSelectedSeries("");
    setSelectedSubSeries("");
    setSelectedLightColor("");
    setSelectedLensColor("");
    setSelectedModel("");
    setSelectedSize("");
    setSelectedSearchText("");
  };

  // used for handle change of series drop-down
  const changeSelectedSeries = (value) => {
    setSelectedObject("");
    setSelectedSeries(value);
    setSelectedSearchText("");
    setSelectedSubSeries("");
    setSelectedLightColor("");
    setSelectedLensColor("");
    setSelectedModel("");
    setSelectedSize("");
  };

  // used for handle change of sub-series drop-down
  const changeSelectedSubSeries = (value) => {
    setSelectedObject("");
    setSelectedSubSeries(value);
    setSelectedSearchText("");
    setSelectedLightColor("");
    setSelectedLensColor("");
    setSelectedModel("");
    setSelectedSize("");
  };

  // used for handle change of light color drop-down
  const changeSelectedLightColor = (value) => {
    setSelectedObject("");
    setSelectedLightColor(value);
    setSelectedSearchText("");
    setSelectedLensColor("");
    setSelectedModel("");
    setSelectedSize("");
  };

  // used for handle change of lens color drop-down
  const changeSelectedLensColor = (value) => {
    setSelectedObject("");
    setSelectedLensColor(value);
    setSelectedSearchText("");
    setSelectedModel("");
    setSelectedSize("");
  };

  // used for handle change of light modal drop-down
  const changeSelectedLightModal = (value) => {
    setSelectedObject("");
    setSelectedModel(value);
    setSelectedSearchText("");
  };

  // used for handle change of size drop-down
  const changeSelectedSize = (value) => {
    setSelectedObject("");
    setSelectedSize(value);
    setSelectedSearchText("");
    setSelectedModel("");
  };

  // used for adding lights to mysource
  const getLabel = () => {
    let lightsToAdd = null;

    lightsData.map((lights) => {
      if (
        lightType !== "Lightbar" &&
        lights.lightType === lightType &&
        lights.series === series &&
        (lights.subSeries !== "" ? lights.subSeries === subSeries : true) &&
        (selectedLightColor.colors
          ? (selectedLightColor.colors.split("/")[0]
              ? lights.color1 === selectedLightColor.colors.split("/")[0]
              : true) &&
            (selectedLightColor.colors.split("/")[1]
              ? lights.color2 === selectedLightColor.colors.split("/")[1]
              : true) &&
            (selectedLightColor.colors.split("/")[2] ? lights.color3 === selectedLightColor.colors.split("/")[2] : true)
          : true) &&
        lights.lensColor === selectedLensColor.lensColor &&
        lights.modelNumber === selectedModel.modelNumber
      ) {
        lightsToAdd = lights;
        lightsToAdd.isAllLightFound = true;
      }

      if (
        lightType === "Lightbar" &&
        lights.lightType === lightType &&
        lights.series === series &&
        (lights.subSeries !== "" ? lights.subSeries === subSeries : true) &&
        lights.size === selectedSize.size &&
        lights.modelNumber === selectedModel.modelNumber
      ) {
        lightsToAdd = lights;
      }

      if (
        lightType !== "Lightbar" &&
        lightType !== "Lighthead" &&
        lightType !== "Beacon" &&
        lights.lightType === lightType &&
        lights.series === series &&
        lights.modelNumber === selectedModel.modelNumber
      ) {
        lightsToAdd = lights;
        lightsToAdd.isAllLightFound = true;
      }

      return null;
    });
    if (lightsToAdd) {
      return lightsToAdd.sDisplayName;
    }
    if (selectedObject) {
      return selectedObject.sDisplayName;
    }
    return "Incomplete";
  };

  const DropdownIndicator = (props) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="fa fa-search search-icon" aria-hidden="true" />
      </components.DropdownIndicator>
    );

  return (
    <>
      <ToastContainer />
      <div className="addlightsource">
        <div className="modal-content">
          <div className="close-icon text-right">
            <button type="button" className="close" data-dismiss="modal" onClick={closeAddLightSourceModal}>
              <span aria-hidden="true">
                <i className="fa fa-times text-red" />
              </span>
            </button>
          </div>
          <h2 className="modal-title text-white text-uppercase">Add a light source</h2>
          <form className="addlightsource-form">
            <div className="form-group has-search">
              <label className="text-uppercase">Choose a light series and model</label>
              <Select
                value={searchTextData}
                onChange={changeSearchText}
                placeholder={<div className="text-capitalize">Choose a light series and model</div>}
                options={searchText}
                className="select-box-lights form-control input-lg"
                onFocus={handleOnFocusbox}
                isSearchable
                styles={helper.getDropDownStyleForSearch()}
                components={{ DropdownIndicator }}
              />
            </div>
            <div className="d-flex flex-wrap">
              <div className="form-group">
                <label className="required">Light Type</label>
                <Select
                  value={selectedLightType}
                  onChange={changeSelectedLightType}
                  options={lightTypes}
                  placeholder={<div className="text-capitalize">Please Select</div>}
                  className="select-dd"
                  autoFocus={false}
                  isSearchable={false}
                  styles={helper.getDropDownStyle(false)}
                />
              </div>
              <div className={`form-group ml-5 pl-1 mr-5 ${!lightType ? "cursor-na" : "cursor-pt"}`}>
                <label className="required">Series</label>
                <Select
                  value={selectedSeries}
                  onChange={changeSelectedSeries}
                  options={lightSeries}
                  placeholder={<div className="text-capitalize">Please Select</div>}
                  className="select-dd"
                  autoFocus={false}
                  isDisabled={!lightType}
                  isSearchable={false}
                  styles={helper.getDropDownStyle(!lightType)}
                />
              </div>
              {(!lightType ||
                lightType === "" ||
                lightType === "Lightbar" ||
                lightType === "Specialty" ||
                lightType === "Lighthead" ||
                lightType === "Beacon") && (
                <div className={`form-group ${!series || !lightSubSeries[1] ? "cursor-na" : "cursor-pt"}`}>
                  <label className="required">Subseries</label>
                  <Select
                    value={selectedSubSeries}
                    onChange={changeSelectedSubSeries}
                    options={lightSubSeries}
                    placeholder={<div className="text-capitalize">Please Select</div>}
                    className="select-dd"
                    autoFocus={false}
                    isDisabled={!series || !lightSubSeries[1]}
                    isSearchable={false}
                    styles={helper.getDropDownStyle(!series || !lightSubSeries[1])}
                  />
                </div>
              )}
              {lightType === "Lightbar" && (
                <>
                  <div className={`form-group ${!subSeries ? "cursor-na" : "cursor-pt"}`}>
                    <label className="required">Size</label>
                    <Select
                      value={selectedSize}
                      onChange={changeSelectedSize}
                      options={lightSize}
                      placeholder={<div className="text-capitalize">Please Select</div>}
                      className="select-dd"
                      autoFocus={false}
                      isDisabled={!subSeries}
                      isSearchable={false}
                      styles={helper.getDropDownStyle(!subSeries)}
                    />
                  </div>
                </>
              )}
              {(!lightType || lightType === "" || lightType === "Lighthead" || lightType === "Beacon") && (
                <>
                  <div className={`form-group ${lightSubSeries[1] ? !subSeries : !series ? "cursor-na" : "cursor-pt"}`}>
                    <label className="required">Light Color</label>
                    <Select
                      value={selectedLightColor}
                      onChange={changeSelectedLightColor}
                      options={lightColors}
                      placeholder={<div className="text-capitalize">Please Select</div>}
                      className="select-dd"
                      autoFocus={false}
                      isDisabled={lightSubSeries[1] ? !subSeries : !series}
                      isSearchable={false}
                      styles={helper.getDropDownStyle(lightSubSeries[1] ? !subSeries : !series)}
                    />
                  </div>
                  <div className={`form-group ml-5 mr-5 pl-1 ${!series ? "cursor-na" : "cursor-pt"}`}>
                    <label className="required">Lens Color</label>
                    <Select
                      value={selectedLensColor}
                      onChange={changeSelectedLensColor}
                      options={lensColors}
                      placeholder={<div className="text-capitalize">Please Select</div>}
                      className="select-dd"
                      autoFocus={false}
                      isDisabled={!selectedLightColor.color1}
                      isSearchable={false}
                      styles={helper.getDropDownStyle(!selectedLightColor.color1)}
                    />
                  </div>
                </>
              )}

              <div
                className={`form-group ${lightType === "Lightbar" && "ml-5 pl-1"} ${
                  (lightType === "Lightbar" && !selectedSize) ||
                  (lightType === "Lighthead" && !selectedLensColor.lensColor) ||
                  (lightType === "Beacon" && !selectedLensColor.lensColor) ||
                  (lightType !== "Lightbar" && lightType !== "Lighthead" && lightType !== "Beacon" && !series)
                    ? "cursor-na"
                    : "cursor-pt"
                }`}
              >
                <label className="required">Model</label>
                <Select
                  value={selectedModel}
                  onChange={changeSelectedLightModal}
                  options={lightsModal}
                  placeholder={<div className="text-capitalize">Please Select</div>}
                  className="select-dd"
                  autoFocus={false}
                  isDisabled={
                    (lightType === "Lightbar" && !selectedSize) ||
                    (lightType === "Lighthead" && !selectedLensColor.lensColor) ||
                    (lightType === "Beacon" && !selectedLensColor.lensColor) ||
                    (lightType !== "Lightbar" && lightType !== "Lighthead" && lightType !== "Beacon" && !series)
                  }
                  isSearchable={false}
                  styles={helper.getDropDownStyle(
                    (lightType === "Lightbar" && !selectedSize) ||
                      (lightType === "Lighthead" && !selectedLensColor.lensColor) ||
                      (lightType === "Beacon" && !selectedLensColor.lensColor) ||
                      (lightType !== "Lightbar" && lightType !== "Lighthead" && lightType !== "Beacon" && !series)
                  )}
                />
              </div>
            </div>

            {lightType === "Lightbar" && (
              <>
                <div className="text-center mt-5">
                  <label>Or import a .bxr file from wecad</label>
                </div>
                <div className="form-group text-center">
                  <div className="file-input-container">
                    <input
                      type="file"
                      onChange={onFileChange}
                      accept=".bxr, .BXR"
                      className="sm-input-file"
                      id="sm-ip-1"
                      ref={fileInput}
                    />
                    <label className="for-sm-input-file btn btn-red" htmlFor="sm-ip-1">
                      Choose File
                    </label>
                    {fileName ? (
                      <span className="span-text" id="file-name">
                        {fileName}
                      </span>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <div className={`text-center ${lightType !== "Lightbar" ? "mt-100px" : "mt-5"}`}>
              <label className="mt-5">{`Light source to be added : ${getLabel()} `}</label>
            </div>
            <div className="d-flex justify-content-center">
              <button type="button" disabled={disable === true} onClick={addLightsToSource} className="btn btn-red">
                {disable === true && <span className="spinner-border spinner-border-sm mr-1" />}
                Add Light Source
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LightModal;
