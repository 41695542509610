import axios from "axios";
import { Subject } from "rxjs";
import configData from "../config/config";

const localData = new Subject();

const setLocalData = (data) => {
  localData.next(data);
};
const getLocalData = () => localData;
// Method use to make get Lights data api request to aws
const getLightsData = async () => {
  const url = `${configData.API_URL}/lights?standalone=true`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// Method use to make get Light-bars data api request to aws
const getLightBarsData = async () => {
  const url = `${configData.API_URL}/lightbars`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

// used for set lights to the localstorage
const setLightsDataToLocalStorage = async (response) => {
  localStorage.setItem("lightsData", JSON.stringify(response));
  setLocalData(true);
};

// used for get lights from the localstorage
const getLightsDataFromLocalStorage = () => {
  if (localStorage.key("lightsData")) {
    return JSON.parse(localStorage.getItem("lightsData"));
  }
  return null;
};

// used for get mysources lights from the localstorage
const getMySourcesFromLocalStorage = () => {
  if (localStorage.key("mySources")) {
    return JSON.parse(localStorage.getItem("mySources"));
  }
  return null;
};

// used for set mysources lights to the localstorage
const setMySourcesToLocalStorage = async (response) => {
  localStorage.setItem("mySources", JSON.stringify(response));
};

// used for get results of the perform calculation
const getPerformCalculationResults = async (
  id,
  excludeInactive,
  excludeLightsInCalculation = [],
  excludeLightsDuoTrioInCalculation = []
) => {
  const url = `${configData.API_URL}/configs/${id}/calculate?includeInactive=${excludeInactive}`;
  const res = await axios({
    method: "POST",
    url,
    data: { excluded_lights: excludeLightsInCalculation, excludedLighstDuoTrio: excludeLightsDuoTrioInCalculation },
  });
  return res;
};

// used for get results of the perform calculation
const getLightbarPreviewData = async (configId, lightId) => {
  const url = `${configData.API_URL}/configs/${configId}/lights/${lightId}`;
  const res = await axios({
    method: "GET",
    url,
  });
  return res;
};

const ZoneServices = {
  getLightsData,
  setLightsDataToLocalStorage,
  getLightsDataFromLocalStorage,
  getMySourcesFromLocalStorage,
  setMySourcesToLocalStorage,
  getLightBarsData,
  getPerformCalculationResults,
  setLocalData,
  getLocalData,
  getLightbarPreviewData,
};

export default ZoneServices;
