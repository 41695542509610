// Header Component
import React, { useState, useEffect } from "react";

// Ui Components
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Navbar from "react-bootstrap/Navbar";
import LeftTopMenu from "./TopLeftMenu";
import UserIcon from "../../assets/images/UserProfile.png";
import authService from "../../services/AuthService";
import ChangePassword from "../Auth/ChangePassword";
import ConfigService from "../../services/ConfigService";

export default () => {
  const [openModal, setModalVisibility] = useState(false);
  const [name, setName] = useState("");
  const [pageName, setPageName] = useState("");

  const getName = () => setTimeout(() => setName(authService.getName()), 1000);

  useEffect(() => {
    getName();
    window.onpopstate = () => null;
  }, []);

  // User clicks on Log out button
  const onSignOut = () => {
    ConfigService.setLogoutOperations(true);
  };

  // used for open change-password modal
  const openChangePasswordModal = () => {
    setModalVisibility(true);
    const page = JSON.parse(JSON.stringify(window.location.href.split("/").pop()));
    setPageName(page);
  };

  // used for close change-password modal
  const closeChangePasswordModal = () => {
    setModalVisibility(false);
  };

  return (
    <>
      <Navbar fixed="top" className="header bg-transparent py-1" style={{ position: "absolute" }}>
        <div className="container-fluid gutters-0">
          <div className="d-flex col">
            <LeftTopMenu />
            <Dropdown className="ml-auto">
              <Dropdown.Toggle as="a" class="nav-link pr-0 leading-none d-flex cursor-pt mt-4">
                <span className="ml-2 home-header" style={{ width: "min-content" }}>
                  <span className="text-white">{name}</span>
                </span>
                <span className="avatar ml-2" style={{ marginRight: "-10px" }}>
                  <img className="img-profile rounded-circle" alt="" src={UserIcon} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right dropdown-menu-arrow">
                <a href="#" className="dropdown-item" onClick={openChangePasswordModal}>
                  <i className="fa fa-lock fa-sm fa-fw mr-2 text-gray-400" />
                  Change Password
                </a>
                <Dropdown.Divider />
                <a href="#" className="dropdown-item" onClick={onSignOut}>
                  <i className="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                  Log Out
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Modal
            className={`changepassword-modal ${pageName.includes("home") ? "" : "margin-lft"}`}
            centered
            backdrop="static"
            keyboard={false}
            show={openModal}
            onHide={closeChangePasswordModal}
          >
            <ChangePassword closeChangePasswordModal={closeChangePasswordModal} />
          </Modal>
        </div>
      </Navbar>
    </>
  );
};
