import React from "react";
import ReactTooltip from "react-tooltip";

const ZoneLightSelection = ({ zoneData, level, checkboxEvent, zoneArea, standardName, excludeLevel }) => (
  <div>
    {level !== "" && <h5 className="text-white">{level}</h5>}
    <div
      className={`details-block align-item-center ${
        (standardName === "CAAS_GSV_V3.0" || standardName !== "KKK_A_1822G" || standardName !== "Ontario_5.0") &&
        zoneArea === "zoneCData" &&
        excludeLevel === "true"
          ? "zone-max-height"
          : ""
      }`}
    >
      <div className="row sub-details sticky">
        <div className="col-md-9 text-white">
          <p>Source</p>
        </div>
        <div className="col-md-3 text-white">
          <p>Action</p>
        </div>
      </div>
      <>
        {Object.keys(zoneData).map(
          (zoneDataArrayIndex) =>
            Object.keys(zoneData[zoneDataArrayIndex]).length > 0 && (
              <>
                <div className="row mb-2 align-items-center">
                  <div key={zoneData[zoneDataArrayIndex]?.level} className="col-md-9">
                    <p data-for={level} className="text-white m-0" data-tip={zoneData[zoneDataArrayIndex]?.tooltipData}>
                      {zoneData[zoneDataArrayIndex]?.lightType === "Lightbar" ||
                      zoneData[zoneDataArrayIndex]?.lightType === "ImportedLightbar" ||
                      zoneData[zoneDataArrayIndex]?.lightType === "Specialty"
                        ? `${
                            zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                          }`
                        : `${
                            zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                          }-${zoneData[zoneDataArrayIndex]?.color1 ? zoneData[zoneDataArrayIndex]?.color1 : ""}`}
                    </p>
                    <ReactTooltip id={level} place="top" multiline type="light" effect="solid" />
                  </div>
                  <div className="col-md-3 h-5" key={zoneData[zoneDataArrayIndex]?.displayId}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        onChange={(e) => {
                          checkboxEvent(
                            e,
                            zoneDataArrayIndex,
                            zoneArea,
                            zoneData[zoneDataArrayIndex].lightId,
                            zoneData[zoneDataArrayIndex].color1 ? zoneData[zoneDataArrayIndex].color1 : "",
                            "color1Selected"
                          ); // updating function event object is empty on uncheck
                        }}
                        value={zoneData[zoneDataArrayIndex].lightId}
                        type="checkbox"
                        checked={zoneData[zoneDataArrayIndex].color1Selected}
                      />
                    </div>
                  </div>
                </div>
                {zoneData[zoneDataArrayIndex].color2 && (
                  <div className="row mb-2 align-items-center">
                    <div key={zoneData[zoneDataArrayIndex]?.level} className="col-md-9">
                      <p
                        data-for={level}
                        className="text-white m-0"
                        data-tip={zoneData[zoneDataArrayIndex]?.tooltipData}
                      >
                        {zoneData[zoneDataArrayIndex]?.lightType === "Lightbar" ||
                        zoneData[zoneDataArrayIndex]?.lightType === "ImportedLightbar" ||
                        zoneData[zoneDataArrayIndex]?.lightType === "Specialty"
                          ? `${
                              zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                            }`
                          : `${
                              zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                            }-${zoneData[zoneDataArrayIndex]?.color2 ? zoneData[zoneDataArrayIndex]?.color2 : ""}`}
                      </p>
                      <ReactTooltip id={level} place="top" multiline type="light" effect="solid" />
                    </div>
                    <div className="col-md-3 h-5" key={zoneData[zoneDataArrayIndex]?.displayId}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onChange={(e) => {
                            checkboxEvent(
                              e,
                              zoneDataArrayIndex,
                              zoneArea,
                              zoneData[zoneDataArrayIndex].lightId,
                              zoneData[zoneDataArrayIndex].color2 ? zoneData[zoneDataArrayIndex].color2 : "",
                              "color2Selected"
                            ); // updating function event object is empty on uncheck
                          }}
                          value={zoneData[zoneDataArrayIndex].lightId}
                          type="checkbox"
                          checked={zoneData[zoneDataArrayIndex].color2Selected}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {zoneData[zoneDataArrayIndex].color3 && (
                  <div className="row mb-2 align-items-center">
                    <div key={zoneData[zoneDataArrayIndex]?.level} className="col-md-9">
                      <p
                        data-for={level}
                        className="text-white m-0"
                        data-tip={zoneData[zoneDataArrayIndex]?.tooltipData}
                      >
                        {zoneData[zoneDataArrayIndex]?.lightType === "Lightbar" ||
                        zoneData[zoneDataArrayIndex]?.lightType === "ImportedLightbar" ||
                        zoneData[zoneDataArrayIndex]?.lightType === "Specialty"
                          ? `${
                              zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                            }`
                          : `${
                              zoneData[zoneDataArrayIndex]?.modelNumber ? zoneData[zoneDataArrayIndex]?.modelNumber : ""
                            }-${zoneData[zoneDataArrayIndex]?.color3 ? zoneData[zoneDataArrayIndex]?.color3 : ""}`}
                      </p>
                      <ReactTooltip id={level} place="top" multiline type="light" effect="solid" />
                    </div>
                    <div className="col-md-3 h-5" key={zoneData[zoneDataArrayIndex]?.displayId}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          onChange={(e) => {
                            checkboxEvent(
                              e,
                              zoneDataArrayIndex,
                              zoneArea,
                              zoneData[zoneDataArrayIndex].lightId,
                              zoneData[zoneDataArrayIndex].color3 ? zoneData[zoneDataArrayIndex].color3 : "",
                              "color3Selected"
                            ); // updating function event object is empty on uncheck
                          }}
                          value={[zoneData[zoneDataArrayIndex].lightId]}
                          type="checkbox"
                          checked={zoneData[zoneDataArrayIndex].color3Selected}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
        )}
      </>
    </div>
  </div>
);

export default ZoneLightSelection;
