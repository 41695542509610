// component for display zone upper section
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import ZoneCards from "./ZonesCard";
import helper from "../../../helper/helper";

const ZoneUpperSection = ({
  zone,
  setObstruct,
  onChangeQuantity,
  onChangeBlockIntensity,
  onChangeCallIntensity,
  deleteLightsFromZone,
  zoneLightsData,
  setRotation,
  isOntario,
  isMirrorToZoneB,
  isMirrorToZoneD,
}) => (
  <div className="upper-zone">
    <div className="d-flex justify-content-between align-items-center">
      <p className="text-uppercase">Upper</p>
      <p className="d-flex justify-content-between">
        <span>Current Draw</span>
        <span>
          Peak:
          {` ${helper.getCurrentMax(zoneLightsData, zone, "upper")}`}
        </span>
        <span>
          Avg:
          {` ${helper.getCurrentAverage(zoneLightsData, zone, "upper")}`}
        </span>
      </p>
    </div>
    <div className="details-block style">
      <Droppable droppableId={`zoneContentMix-${zone}-upper`} type="TASK">
        {(provided, snapshot) => (
          <div
            className="section-scroll"
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {(zone === "A" && zoneLightsData.zoneADataUpper.length) ||
            (zone === "B" && zoneLightsData.zoneBDataUpper.length) ||
            (zone === "C" && zoneLightsData.zoneCDataUpper.length) ||
            (zone === "D" && zoneLightsData.zoneDDataUpper.length) ? (
              <div className="row sub-details sticky">
                <div className="col-md-3  m-0">
                  <p>Source</p>
                </div>
                <div className="col-md-9 light-width">
                  <div className="row justify-content-between custom-width">
                    <p>Qty</p>
                    <p>Calling Intensity</p>
                    <p>Blocking Intensity</p>
                    <p>Obstructed</p>
                    <p>Rotation</p>
                    <p style={{ width: "10px" }} />
                  </div>
                </div>
              </div>
            ) : null}
            {zone === "A" && zoneLightsData.zoneADataUpper && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneADataUpper}
                onChangeQuantity={onChangeQuantity}
                zone="zoneADataUpper"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "B" && zoneLightsData.zoneBDataUpper && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneBDataUpper}
                onChangeQuantity={onChangeQuantity}
                zone="zoneBDataUpper"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "C" && zoneLightsData.zoneCDataUpper && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneCDataUpper}
                zone="zoneCDataUpper"
                onChangeQuantity={onChangeQuantity}
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
            {zone === "D" && zoneLightsData.zoneDDataUpper && (
              <ZoneCards
                deleteLightsFromZone={deleteLightsFromZone}
                setObstruct={setObstruct}
                onChangeBlockIntensity={onChangeBlockIntensity}
                onChangeCallIntensity={onChangeCallIntensity}
                renderData={zoneLightsData.zoneDDataUpper}
                onChangeQuantity={onChangeQuantity}
                zone="zoneDDataUpper"
                setRotation={setRotation}
                isOntario={isOntario}
                isMirrorToZoneB={isMirrorToZoneB}
                isMirrorToZoneD={isMirrorToZoneD}
              />
            )}
          </div>
        )}
      </Droppable>
    </div>
  </div>
);

export default ZoneUpperSection;
